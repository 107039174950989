import { Injectable } from '@angular/core';
import { HttputilityService } from '../services/httputility.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private _httputilityService: HttputilityService) {}
  //baseUrl: any = 'http://132.148.242.138/FPDMS/api/';
  //UMSbaseUrl: any = 'http://132.148.242.138/FPUMS/api/user/';

  getDocumentTypesTableData() {
    return this._httputilityService.Get('AllDocumentTypes?active='+'');
  }

  docTypes(model) {
    return this._httputilityService.Add('CreateDocType', model);
  }

  editdocTypes(model) {
    return this._httputilityService.Add('UpdateDocType', model);
  }

  // bindDocumentTypesData(typeID) {
  //   return this._httputilityService.Get('GetDocTypeByID');
  // }

  deleteDoc(DTID) {
    debugger;
    return this._httputilityService.Add('DeleteDocType?DTID=' + DTID,'');
  }

  deleteProfile(pfID) {
    debugger;
    return this._httputilityService.Add('DeleteProfile?PID=' + pfID, '');
  }

  getClausesData(model) {
    return this._httputilityService.Add("GetClauses", model);
  }

  getRoles() {
    return this._httputilityService.GetU('user/GetRoles');
  }

  RoleList(DepartmentGUID) {
    debugger;
    return this._httputilityService.GetU('user/GetRolesByDepartment?DepartmentGUID=' + DepartmentGUID,
      ''
    );
  }

  createDelegation(model) {
    return this._httputilityService.Add('AddDelegation', model);
  }

  GetAllDelegations() {
    return this._httputilityService.Get('AllDelegations');
  }

}
