import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-projectchangerequest',
  templateUrl: './projectchangerequest.component.html',
  styleUrls: ['./projectchangerequest.component.scss']
})
export class ProjectchangerequestComponent implements OnInit {
  ECRN:any;
  PrjStatus:any = "New";
  ChangeType:any="Project";
  selectedDate:any;
  Description:string = "";
  BusinesBenifits:string = "";
  Justification:string = "";
  isReadOnly: boolean=false;
  projectName:any;
  ProdOwner:any;
  ItemDescription:any;
  ActivePhase:any;
  ProjectStartDate:any;
  ProposedProjectStartDate:any;
  prjDetails:any;
  TaskData:any;
  isProjectStartDateEdit:boolean = false;

  commentsList:any=[];
  commentsDisplay:boolean;

  userId = sessionStorage.getItem('userGuid');
  @ViewChild("dt") table: Table;
  showLoading:boolean;
  txtErrorMessage:string;
  ChangeRequestGuid:string;
  
  isFromApprovalNeeded:boolean;
  isFromOther:boolean;
  Comments:string;
  ReqId:Int16Array;
  projectId:string;
  
  datesData:any[]=[];
  rejectdisplay:boolean;
  approvedisplay:boolean;
  changeRequestId:any;
  productLine:any;
  approvalsHistory:any=[];
  
  Rejectcomments:any;
  txtErrorMessageAR:any;
  
  expandedRows: {} = {};
  nonWorkingDaysCount: number = 0;
  effectedTasksdisplay:boolean=false;
  effectedTasksData:any=[];
  isInprocess:boolean;
  ccbList:any=[];
  ShowCCB:boolean;
  constructor(
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  ngOnInit(): void {
    this.Comments = "Approved";
    var source = this.route.snapshot.queryParamMap.get('source');
    if(source=="AN"){
      this.isFromApprovalNeeded=true;
      this.isFromOther= false;
    }
    else{
      this.isFromApprovalNeeded=false;
      this.isFromOther= true;
    }
    this.selectedDate = new Date();
    var prjId = this.route.snapshot.queryParamMap.get('prjId');
    if(prjId!=null && prjId!=""&& prjId!=undefined)
      this.GetProjectDetails(prjId);
    var changeReqId = this.route.snapshot.queryParamMap.get('id');
    if(changeReqId!=null && changeReqId!=""&& changeReqId!=undefined){
      this.getChangeRequestDetails(changeReqId);
    }
  }

  GetProjectDetails(prjId){
    this.showLoading = true;
    this._facadeService.GetProjectDetailsDates(prjId,true).subscribe(
      (data) => {
        this.prjDetails = data.returnObject;
        this.projectName = this.prjDetails.projectName;
        this.projectId = this.prjDetails.projectId;
        this.ProdOwner = this.prjDetails.projectManger;
        this.ActivePhase = this.prjDetails.currentPhase;
        this.productLine = this.prjDetails.productLine;
        this.ItemDescription = this.prjDetails.projectDescription;
        this.ProjectStartDate = this.datePipe.transform(this.prjDetails.projectStartDate, "MM/dd/yyyy");
        this.ProposedProjectStartDate = this.datePipe.transform(this.prjDetails.projectStartDate, "MM/dd/yyyy");
        this.isProjectStartDateEdit = this.prjDetails.isProjectStartDateEdit;
        if(this.isProjectStartDateEdit)
        {
          if(this.prjDetails.projectActualStartDate != null && this.prjDetails.projectActualStartDate != undefined)
            this.isProjectStartDateEdit = false;
        }
        
        this.TaskData = this.prjDetails.projectTasks;
        this.TaskData.forEach(element => {
          element.reason = "";
        });
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  Getdates(Type,taskid)
  {
    this.txtErrorMessage = "";
    this.showLoading = true;
    var ok = true;
    if(Type == "TT" || Type == "TC")
    {
      if(this.TaskData!=null && this.TaskData.length>0){
        this.TaskData.forEach(element => {
          if(element.taskId == taskid)
          {   
            if(Type == "TT")
            {
              if(element.newTargetEndDate == undefined || element.newTargetEndDate == null || element.newTargetEndDate == "--" || element.newTargetEndDate == "")
              {
                this.txtErrorMessage = "Please enter Proposed target end date";
                ok = false; this.showLoading = false;
              }
              else
              {
                element.newTargetEndDate = this.datePipe.transform(element.newTargetEndDate, "MM/dd/yyyy");
                if(this.datePipe.transform(element.newTargetEndDate, "MM/dd/yyyy") != this.datePipe.transform(element.targetEndDate, "MM/dd/yyyy"))
                  element.proposedTargetOverride = true;
              }
            }
            if(Type == "TC")
            {
              if(element.newCurrentEndDate == undefined || element.newCurrentEndDate == null || element.newCurrentEndDate == "--" || element.newCurrentEndDate == "")
              {
                this.txtErrorMessage = "Please enter Proposed current end date";
                ok = false; this.showLoading = false;
              }
              else
              {
                element.newCurrentEndDate = this.datePipe.transform(element.newCurrentEndDate, "MM/dd/yyyy");
                if(this.datePipe.transform(element.newCurrentEndDate, "MM/dd/yyyy") != this.datePipe.transform(element.currentEndDate, "MM/dd/yyyy"))
                  element.proposedCurrentOverride = true;
              }
            }
          }
        });
      }
    }
    if(Type == "P" && ok)
    {
      if(this.ProposedProjectStartDate == undefined || this.ProposedProjectStartDate == null || this.ProposedProjectStartDate == "--" || this.ProposedProjectStartDate == "")
      {
        this.txtErrorMessage = "Please enter Proposed project target start date";
        ok = false; this.showLoading = false;
      }
      else
        this.ProposedProjectStartDate = this.datePipe.transform(this.ProposedProjectStartDate, "MM/dd/yyyy");
    }
    
    if(ok)
    {
      var startdate = new Date(this.datePipe.transform(this.ProposedProjectStartDate,"MM/dd/yyyy"));
      let da = this.datePipe.transform(startdate,"MM/dd/yyyy");
      this._facadeService.GetDates(this.TaskData,da).subscribe(
        (data) => {
          if(data.returnCode==0)
          {
            this.showLoading = false;
            this.TaskData = data.returnObject;
          }
          else{
            this.showLoading = false;
            this.txtErrorMessage=data.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        } 
      );
    }
  }
  BackClick(){
    var source = this.route.snapshot.queryParamMap.get('source');
    if(source=="CR"){
      this.router.navigate(["/changerequests"]);
    }
    else if(source=="AN"){
      this.router.navigate(["/approvalsneeded"]);
    }
    else if(source=="D"){
      this.router.navigate(["/dashboard"]);
    }
    else if(source=="R"){
      this.router.navigate(["/reports"]);
    }
    else {
      this.router.navigate(["/createnewrequestProject",{ S: 'Project' }]);
    }
  }

  ChangeRequest(sendForApproval){
    this.txtErrorMessage ="";
    if(this.selectedDate==""||this.selectedDate==undefined||this.selectedDate==null){
      this.txtErrorMessage="Please select Date";
    }
    else if(this.Description==""||this.Description==undefined||this.Description==null){
      this.txtErrorMessage="Please enter description";
    }
    else if(this.BusinesBenifits==""||this.BusinesBenifits==undefined||this.BusinesBenifits==null){
      this.txtErrorMessage="Please enter reasons";
    }
    else{
      var changeReqId = this.route.snapshot.queryParamMap.get('id');

      if(sendForApproval=="SP"){
          if(changeReqId!=null && changeReqId!=""&& changeReqId!=undefined){
            this.UpdateChangeRequest(sendForApproval);
          }
          else{
            this.CreateChangeRequest(sendForApproval);
          }
      }
      else{
        if(changeReqId!=null && changeReqId!=""&& changeReqId!=undefined){
          this.UpdateChangeRequest(sendForApproval);
        }
        else{
          this.CreateChangeRequest(sendForApproval);
        }
      }
    }
  }

  getChangeRequestDetails(changeId){
    this.showLoading = true;
    this._facadeService.GetChangeRequestDetailsById(changeId).subscribe(
      (data) => {
        this.prjDetails = data.returnObject;
        console.log("Project Response data:"+JSON.stringify(this.prjDetails));
        if(this.prjDetails!=null){
          this.changeRequestId= this.prjDetails.id;
          this.ECRN=this.prjDetails.ecrn;
          this.ProdOwner=this.prjDetails.itemOwner;
          this.PrjStatus=this.prjDetails.statusName;
          this.ChangeType=this.prjDetails.changeGroupName;
          this.ActivePhase=this.prjDetails.activePhase;
          this.Description=this.prjDetails.description;
          this.Justification=this.prjDetails.justification;
          this.BusinesBenifits=this.prjDetails.businessBenefits;
          this.selectedDate= new Date(this.prjDetails.date);
          this.projectName=this.prjDetails.name;
          this.ChangeRequestGuid=this.prjDetails.changeRequestGuid;
          this.ReqId=this.prjDetails.id;
          this.ItemDescription = this.prjDetails.itemDescription;
          this.productLine = this.prjDetails.productLine;
          this.projectId = this.prjDetails.itemGuid;
          this.ProjectStartDate = this.datePipe.transform(this.prjDetails.projectStartDate, "MM/dd/yyyy");
          this.ProposedProjectStartDate = this.datePipe.transform(this.prjDetails.proposedProjectStartDate, "MM/dd/yyyy");
          this.isProjectStartDateEdit = this.prjDetails.isProjectStartDateEdit;
          this.TaskData = this.prjDetails.projectChangeTasks;
          if(this.PrjStatus!="New"){
            this.isReadOnly=true;
            this.isFromOther=false;
            this.GetApprovalsHistory();
          }
          if(this.prjDetails.statusId==3 || this.prjDetails.statusId==4){
            this.isFromOther=false;
            this.isFromApprovalNeeded=false;
          }
          var source = this.route.snapshot.queryParamMap.get('source');
          if(source=="AN"){
            var ccb= JSON.parse(this.route.snapshot.queryParamMap.get('CCBApproved'));
            if(ccb){
              this.isFromOther=false;
              this.isFromApprovalNeeded=false;
            }
          }
          if(this.prjDetails.isInprocess)
            this.isInprocess=true;
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  ApproveRejectRequest(statusId){
    if(statusId==4 && (this.Rejectcomments=="" ||this.Rejectcomments==undefined||this.Rejectcomments==null)){
     this.txtErrorMessageAR="Please enter comments";
    }
    else{
      var model={
        StatusId :statusId,
        ChangeRequestId :this.ReqId,
        ApprovedBy : this.userId,
        Comment:statusId==3?this.Comments:this.Rejectcomments,
        LocalTime:new Date()
      }
      this.showLoading = true;
      this._facadeService.ApproveRejectChangeRequest(model).subscribe(
        (data) => {
          if(data!=null&& data!=undefined&&data!=""&&data.returnCode==0){
            if(statusId==3){
              location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'approvalsneeded?status=Approved';
            }
            else{
              location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'approvalsneeded?status=Rejected';
            }
          }
          else{
            this.txtErrorMessageAR="Unable to save data. Please try again.";
          }
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        } 
      );
    }
  }

  GetApprovalsHistory(){
    this.showLoading = true;
    this._facadeService.GetApprovalsHistory(this.userId,this.changeRequestId).subscribe(
      (data) => {
        if(data.returnObject!=null && data.returnObject.length>0){
          data.returnObject.forEach((value, key) => {
            if(value.approvedDate!=null){
              let convertactiondate = new Date(value.approvedDate+'Z') 
              value.approvedDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
               this.approvalsHistory.push(value);
              if (value.comment != null && value.comment != "") {
                this.commentsList.push(value);
              }
            }
            else{
              this.approvalsHistory.push(value);
              if (value.comment != null && value.comment != "") {
                this.commentsList.push(value);
              }
            }
          });
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  CreateChangeRequest(sendForApproval){
    let datesChanged= false;
    if(this.datePipe.transform(this.ProjectStartDate, "MM/dd/yyyy") != this.datePipe.transform(this.ProposedProjectStartDate, "MM/dd/yyyy"))
      datesChanged = true;
    else
    {
      if(this.TaskData!=null && this.TaskData!=undefined && this.TaskData.length>0)
      {
        this.TaskData.forEach(element => {
          if((element.proposedTargetOverride || element.proposedCurrentOverride) && !datesChanged)
            datesChanged=true;
        });
      }
    }
    if(sendForApproval=="SP"){
      if(!datesChanged){
        this.txtErrorMessage="Please change atleast one Project/task date for change request.";
        return;
      }
    }
    this.showLoading = true;
    var model={
      changeGroupId: 2,
      name: this.projectName, 
      changeItem: this.projectName,
      itemOwner:this.ProdOwner,
      activePhase:this.ActivePhase,
      description: this.Description,
      date: this.selectedDate,
      businessBenefits: this.BusinesBenifits,
      additionalInfo : this.BusinesBenifits,
      justification: this.Justification,
      createdBy: this.userId,
      saveAndSendForApproval: sendForApproval=="SP"?true:false,
      managerGuid:sessionStorage.getItem('ManagerGuid'),
      itemGuid:this.projectId,
      itemDescription:this.ItemDescription,
      projectChangeTasks:this.TaskData,
      productLine:this.productLine,
      projectStartDate : new Date(this.ProjectStartDate),
      proposedProjectStartDate : new Date(this.ProposedProjectStartDate),
      isProjectStartDateEdit : this.isProjectStartDateEdit
    }
    this._facadeService.CreateChangeRequest(model).subscribe(
      (data) => {
        console.log("Create Request:"+JSON.stringify(data))
        if(data!=null&& data!=undefined&&data!=""&&data.returnCode==0){
          if(sendForApproval=="SP")
            location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'changerequests?status=In-Process';
          else
            this.router.navigate(["/changerequests"]);
        }
        else
          this.txtErrorMessage="Unable to save data. Please try again.";
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  UpdateChangeRequest(sendForApproval){
    let datesChanged= false;
    if(this.datePipe.transform(this.ProjectStartDate, "MM/dd/yyyy") != this.datePipe.transform(this.ProposedProjectStartDate, "MM/dd/yyyy"))
      datesChanged = true;
    else
    {
      if(this.TaskData!=null && this.TaskData!=undefined && this.TaskData.length>0)
      {
        this.TaskData.forEach(element => {
          if((element.proposedTargetOverride || element.proposedCurrentOverride) && !datesChanged)
            datesChanged=true;
        });
      }
    }
    if(sendForApproval=="SP"){
      if(!datesChanged){
        this.txtErrorMessage="Please change atleast one Project/task date for change request.";
        return;
      }
    }
    this.showLoading = true;
    var model={
      name: this.projectName, 
      changeItem: this.projectName,
      description: this.Description,
      date: this.selectedDate,
      businessBenefits: this.BusinesBenifits,
      justification: this.Justification,
      additionalInfo : this.BusinesBenifits,
      createdBy: this.userId,
      saveAndSendForApproval: sendForApproval=="SP"?true:false,
      managerGuid:sessionStorage.getItem('ManagerGuid'),
      itemDescription:this.ItemDescription,
      projectChangeTasks:this.TaskData,
      projectStartDate : new Date(this.ProjectStartDate),
      proposedProjectStartDate : new Date(this.ProposedProjectStartDate),
      isProjectStartDateEdit : this.isProjectStartDateEdit,
      changeRequestGuid:this.ChangeRequestGuid
    }
    this._facadeService.UpdateChangeRequest(model).subscribe(
      (data) => {
        console.log("Update Response:"+JSON.stringify(data))
        if(data!=null&& data!=undefined&&data!=""&&data.returnCode==0){
          if(sendForApproval=="SP")
            location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'changerequests?status=In-Process';
          else
            this.router.navigate(["/changerequests"]);
        }
        else
          this.txtErrorMessage="Unable to save data. Please try again.";
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  approveRej(statusId){
    this.txtErrorMessageAR="";
    if(statusId==4){
this.rejectdisplay=true;
    }
    else{
this.approvedisplay=true;
    }
  } 
 
  ViewSchedule(taskId,proposed,type,name){
    if(proposed!="" && proposed!="" && proposed!=undefined){
      proposed =  this.datePipe.transform(proposed, "MM/dd/yyyy");
    }
    let projectTYpe="";
    
    this.effectedTasksData=[];
    var aed = this.datePipe.transform(proposed, "MM/dd/yyyy");
    if(name=="Project Start Date"){
      projectTYpe="start"
    }
    else{
      projectTYpe="end"
    }
    this.showLoading = true;
    if(type=="Task"){
      this.effectedTasksdisplay= true;
      this._facadeService.GetEffectedTasks(taskId,aed,"planned",false)
      .subscribe((response) => {
          this.showLoading = false;
                if(response.returnObject!=null && response.returnObject.length>0){
        const thisRef = this;
                  response.returnObject.forEach((value1, key) => {
          if(value1.dates!=null && value1.dates.length>0){
            value1.dates.forEach((value, key) => {
              let convertactiondate = new Date(value.current); 
              value.current =  convertactiondate.toLocaleDateString();
              let convertactiondateProp = new Date(value.proposed); 
              value.proposed =  convertactiondateProp.toLocaleDateString();
            });
            this.effectedTasksData.push(value1);
          }
          thisRef.expandedRows[value1.taskId] = true;
        });
  
  this.expandedRows = Object.assign({}, this.expandedRows);
      }
      },
      (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      this._facadeService.GetProjectDates(this.projectId,projectTYpe,aed,false).subscribe(
          (response) => {
              this.showLoading = false;
              if(response.returnCode==0){
                this.effectedTasksdisplay= true;
                if(response.returnObject!=null && response.returnObject.length>0){
                  const thisRef = this;
                            response.returnObject.forEach((value1, key) => {
                    if(value1.dates!=null && value1.dates.length>0){
                      value1.dates.forEach((value, key) => {
                        let convertactiondate = new Date(value.current); 
                        value.current =  convertactiondate.toLocaleDateString();
                        let convertactiondateProp = new Date(value.proposed); 
                        value.proposed =  convertactiondateProp.toLocaleDateString();
                      });
                      this.effectedTasksData.push(value1);
                    }
                    thisRef.expandedRows[value1.taskId] = true;
                  });
            
            this.expandedRows = Object.assign({}, this.expandedRows);
                }
              }
             else{
               alert(response.returnMessage);
             }
          },
          (error) => {
            this.effectedTasksdisplay= true;
            this.showLoading = false;
            console.log(error);
          } );
    }
}
ViewCCB(){
  this.showLoading = true;
  this.ShowCCB=true;
  this._facadeService.ViewCCB(this.productLine).subscribe(
    (data) => {
      console.log("CCB data:"+JSON.stringify(data.returnObject));
      if(data.returnObject!=null && data.returnObject.approvalBoards!=null && data.returnObject.approvalBoards.length>0){
        this.ccbList=data.returnObject.approvalBoards;
      }
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    } 
  );
}
}

