import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class ChangerequestsService {

  constructor(private _httputilityService: HttputilityService) {}

  getChangeRequestsData(data) {
    return this._httputilityService.Post('GetChangeRequestDetails',data);
  }
  GetChangeRequestDetailsById(ChangeRequestGuid){
    return this._httputilityService.Get('GetChangeRequestDetailsById?ChangeRequestGuid='+ChangeRequestGuid);
  }
  GetReportData(reportData){
    return this._httputilityService.Post('GetReportDetails',reportData);
  }
  getDepartments(){
    return this._httputilityService.GetU('user/GetDepartments');
  }
  GetPartDetails(userId,type){
    return this._httputilityService.GetPDM('dashboard/GetUserProducts?UserID='+userId+'&Type='+type+'&PE=true');
  }
  GetChangeRequestProjectProductsByUser(userId,changeType){
    return this._httputilityService.Get('GetChangeRequestProjectProductsByUser?userId='+userId+'&changeType='+changeType);
  }
  GetChangeRequestProjects(changeType){
    return this._httputilityService.Get('GetChangeRequestProjects?changeType='+changeType);
  }
  getPartCustomAttributes(ProductID,ApplicationID){
    return this._httputilityService.GetPDM('CustomFields/GetChangeMangementFields?ProductID='+ProductID+'&ApplicationID='+ApplicationID);
  }
  GetEffectedTasks(TaskID,ChangeDate,Type,Update){
    return this._httputilityService.GetPRJ('Tasks/GetEffectedTasks?TaskID='+TaskID+'&ChangeDate='+ChangeDate+'&Type='+Type+'&Update='+Update);
  }
  DataSourceAsQuery(datasource){
    return this._httputilityService.GetPDM('CustomFields/Getdata?Query='+ datasource);
  }
  GetProjectDetails(module,userId){
    return this._httputilityService.GetPRJ('Projects/GetProjects?ModuleID='+module+'&UserGUID='+userId);
  }
  GetProjectDetailsDates(projectId,isTasksRequired){
    return this._httputilityService.GetPRJ('ProjectTaskChangeManagement/GetProjectDetails?projectId='+projectId+'&isTasksRequired='+isTasksRequired);
  }
  GetDates(model,Date){
    return this._httputilityService.PostPRJ('ProjectTaskChangeManagement/GetDates?strDate='+Date,model);
  }
  getProjectDetailsById(prjId){ 
    return this._httputilityService.GetPRJ('Projects/GetProjectByID?ProjectID='+prjId);
  }
  getProjectDescription(){
    return this._httputilityService.GetPRJ('Projects/GetAllProjectDescriptions');
  }
  GetDocumentDetails(userId,type){
    return this._httputilityService.GetDMS('Dashboard/GetUserProducts?UserID='+userId+'&Type='+type);
  }
  getPartDetailsById(partId,userId){
    return this._httputilityService.GetPDM('Product/GetProductDetailsbyID?ProductID='+partId+'&UserID='+userId);
  }
  GetApprovals(data){
    return this._httputilityService.Post('GetApprovalChangeRequest',data);
  }
  CreateChangeRequest(model){
    return this._httputilityService.Post('CreateChangeRequest',model);
  }
  UpdateChangeRequest(model){
    return this._httputilityService.Post('UpdateChangeRequest',model);
  }
  UpdateDocChangeRequest(model){
    return this._httputilityService.Post('UpdateDocChangeRequest',model);
  }
  ApproveRejectChangeRequest(model){
    return this._httputilityService.Post('ApproveRejectChangeRequest',model);
  }
  GetAllMfgParts(){
    return this._httputilityService.GetPDM('Product/GetAllMfgParts');
  }
  GetApprovalsHistory(userId, changeRequestId){
    return this._httputilityService.Get('GetApprovalsHistory?userId='+userId+'&changeRequestId='+changeRequestId);
  }
  getApprovals(id) {
    return this._httputilityService.GetDMS('GetPreApprovers?documentId=' + id);
  }
  SaveCCBBoardLine(cCBBoardLines){
    return this._httputilityService.Post('SaveCCBBoardLine',cCBBoardLines);
  }
  UpdateCCBBoardLine(cCBBoardLines){
    return this._httputilityService.Post('UpdateCCBBoardLine',cCBBoardLines);
  }
  GetCCBDetails(){
    return this._httputilityService.Get('GetCCBDetails');
  }
  getCCBDetailsById(id){
    return this._httputilityService.Get('getCCBDetailsById?id='+id);
  }
  GetProjectDates(ProjectID,Type,ChangeDate,Update){
    return this._httputilityService.GetPRJ('Project/GetProjectDates?ProjectID='+ProjectID+'&Type='+Type+'&ChangeDate='+ChangeDate+'&Update='+Update);
  }
  ViewCCB(productLineName){
    return this._httputilityService.Get('GetCCBBoardByProductLineId?productLineName='+productLineName);
  }
  UpdateSecurityToken(token){
    return this._httputilityService.PostU("user/UpdateSecurityToken?Token=" + token ,null);
  }
  CreateSecurityToken(){
    return this._httputilityService.PostU("user/CreateSecurityToken" ,null);
  }
  GetMenusByUser(userId,applicationid){
    return this._httputilityService.GetU('GetMenusByUser?userid='+userId+'&applicationid='+applicationid);

  }
  AddUserTracking(model){
    return this._httputilityService.PostU('user/AddUserTracking',model);
  }
}
