import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoginService } from "../services/login.service";
import { DashboardService } from "../services/dashboard.service";
import { AdminService } from "../services/admin.service";
import { CommonService } from "../services/common.service";
import { BASE_API_URL_TOKEN } from '../injectors';
import { ChangerequestsService } from "../services/Changerequests.service";
import { API_ENDPOINTS } from "../app.component";

@Injectable({
  providedIn: "root",
})
export class FacadeService {
  constructor(
    private _loginService: LoginService,
    private _dashboardService: DashboardService,
    private _adminService: AdminService,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _changeRequests:ChangerequestsService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}

  CreateChangeRequest(model){
    return this._changeRequests.CreateChangeRequest(model);
  }
  GetChangeRequestDetailsById(ChangeRequestGuid){
    return this._changeRequests.GetChangeRequestDetailsById(ChangeRequestGuid);
  }
  GetReportData(reportData){
    return this._changeRequests.GetReportData(reportData);
  }
  getDepartments(){
    return this._changeRequests.getDepartments();
  }
  UpdateChangeRequest(model){
    return this._changeRequests.UpdateChangeRequest(model);
  }
  UpdateDocChangeRequest(model){
    return this._changeRequests.UpdateDocChangeRequest(model);
  }
  getApprovals(id) {
    return this._changeRequests.getApprovals(id);
  }
  GetApprovalsHistory(userId, changeRequestId){
    return this._changeRequests.GetApprovalsHistory(userId, changeRequestId);
  }
  GetAllMfgParts(){
    return this._changeRequests.GetAllMfgParts();
  }
  ApproveRejectChangeRequest(model){
    return this._changeRequests.ApproveRejectChangeRequest(model);
  }

  getChangeRequestsData(data) {
    return this._changeRequests.getChangeRequestsData(data);
  }
  GetPartDetails(userId,type){
    return this._changeRequests.GetPartDetails(userId, type);
  }
  GetChangeRequestProjectProductsByUser(userId,changeType){
    return this._changeRequests.GetChangeRequestProjectProductsByUser(userId, changeType);
  }
  GetChangeRequestProjects(changeType){
    return this._changeRequests.GetChangeRequestProjects( changeType);
  }
  GetProjectDetails(module,userId){
    return this._changeRequests.GetProjectDetails(module,userId);
  }
  GetProjectDetailsDates(projectId,isTasksRequired){
    return this._changeRequests.GetProjectDetailsDates(projectId,isTasksRequired);
  }
  GetDates(model,ProjectDate){
    return this._changeRequests.GetDates(model,ProjectDate);
  }
  getProjectDetailsById(prjId){
    return this._changeRequests.getProjectDetailsById(prjId);
  }
  getProjectDescription(){
    return this._changeRequests.getProjectDescription();
  }
  GetDocumentDetails(userId,status){
    return this._changeRequests.GetDocumentDetails(userId, status);
  }
  getPartDetailsById(partId,userId){
    return this._changeRequests.getPartDetailsById(partId, userId);
  }
  getPartCustomAttributes(ProductID,ApplicationID){
    return this._changeRequests.getPartCustomAttributes(ProductID, ApplicationID);
  }
  GetEffectedTasks(TaskID,ChangeDate,Type,Update){
    return this._changeRequests.GetEffectedTasks(TaskID,ChangeDate,Type,Update);
  }
  DataSourceAsQuery(datasource){
    return this._changeRequests.DataSourceAsQuery(datasource);
    
  }
  getDashboardCounts(userId) {
    return this._dashboardService.getDashboardCounts(userId);
  }

  getDashboardTimeline(userId,type) {
    return this._dashboardService.getDashboardTimelineData(userId,type);
  }

  GetDashboardTableData30Days(userId){
    return this._dashboardService.GetDashboardTableData30Days(userId);
  }
  mydocsData(model) {
    return this._dashboardService.mydocumentsData(model);
  }

  GetCMSECODocuments(userId){
    return this._dashboardService.GetCMSECODocuments(userId);
  }
  GetResubmitDocumentDetails(userId){
    return this._dashboardService.GetResubmitDocumentDetails(userId);
  }
  GetCMSRevisionDocuments(userId){
    return this._dashboardService.GetCMSRevisionDocuments(userId);
  }

  GetApprovals(data){
    return this._changeRequests.GetApprovals(data);
  }

  login(model) {
    return this._loginService.login(model);
  }
  

  GetUserDetails(userGuid){
    return this._dashboardService.GetUserDetails(userGuid); 
  }

  getDashboardTableData(model) {
    return this._dashboardService.getDashboardTableData(model);
  }

  getChartData(userId) {
    return this._dashboardService.getChartData(userId);
  }
  getDocTypes() {
    return this._commonService.getDocTypes();
  }

  getDepartMents() {
    return this._commonService.getDepartMents();
  }

  getRolesByDepartmentGuid(departMentGuid) {
    return this._commonService.getRolesByDepartmentGuid(departMentGuid);
  }

  getRolesByDepartmentGuids(departMentGuids) {
    return this._commonService.getRolesByDepartmentGuids(departMentGuids);
  }

  createProfile(data) {
    return this._http.post(API_ENDPOINTS.PLMAPI+"CreateProfile",
      data
    );
  }

  updateProfile(data) {
    return this._http.post(API_ENDPOINTS.PLMAPI+"UpdateProfile",
      data
    );
  }
  getAllWorkDefinitions() {
    return this._http.get(
      this.baseUrl["API_ENDPOINTS"]["ELSAAPI"]+"DMSWorkflow/GetWorkflowDefinitions"
    );
  }
  getDocumentTypesTableData() {
    return this._adminService.getDocumentTypesTableData();
  }

  deleteProfile(docId) {
    return this._adminService.deleteProfile(docId);
  }
  

  getDocDetailsData(model) {
    return this._dashboardService.getDashboardDataFor30Days(model);
  }
  getRolesData() {
    return this._adminService.getRoles();
  }

  RolesList(DepartmentGUID) {
    debugger;
    return this._adminService.RoleList(DepartmentGUID);
  }

  GetUsersByRoleGuid(RoleGUID) {
    return this._commonService.GetUsersByRoleGuid(RoleGUID);
  }

  getUsers() {
    return this._commonService.getUsers();
  }
  GetProductLines(){
    return this._commonService.GetProductLines();
  }
  SaveCCBBoardLine(cCBBoardLines){
    return this._changeRequests.SaveCCBBoardLine(cCBBoardLines);
  }
  UpdateCCBBoardLine(cCBBoardLines){
    return this._changeRequests.UpdateCCBBoardLine(cCBBoardLines);
  }
  GetCCBDetails(){
    return this._changeRequests.GetCCBDetails();
  }
  getCCBDetailsById(id){
    return this._changeRequests.getCCBDetailsById(id);
  }
  GetProjectDates(ProjectID,Type,ChangeDate,Update){
    return this._changeRequests.GetProjectDates(ProjectID,Type,ChangeDate,Update);
  }
  ViewCCB(productLineName){
    return this._changeRequests.ViewCCB(productLineName);
  }
  CreateSecurityToken(){
    return this._changeRequests.CreateSecurityToken();
  }
  UpdateSecurityToken(token){
    return this._changeRequests.UpdateSecurityToken(token);
  }
  GetMenusByUser(userId,applicationid){
    return this._changeRequests.GetMenusByUser(userId,applicationid);
  }
  AddUserTracking(model){
    return this._changeRequests.AddUserTracking(model);
  }
}
