import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  cols: any[];
  showLoading:boolean;
 ccbData:any=[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  userFeature:boolean=false;
  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  ngOnInit(): void {
      this.cols = [
        { field: 'name', header: 'Board Name', dynamicPlaceHolder: 'Search' },
        { field: 'productName', header: 'Product Line', dynamicPlaceHolder: 'Search' }
      ];
      const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="CMS_DSBL_NEW_EDIT");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;
      }
    }
      this.GetCCBDetails();
  }
GetCCBDetails(){
  this.showLoading = true;
    this._facadeService.GetCCBDetails().subscribe(
      (data) => {
        this.showLoading = false;
        this.ccbData=data.returnObject;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
}
EditCCBDetails(selectedRow){
  location.href=this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'admin/createccbcontrol?id='+selectedRow.ccbGuid;
}

  exportExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 450 },{ wpx : 450 }];
    /* save to file */
    XLSX.writeFile(wb, "Change Control Board.xlsx");
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['C1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
saveAsExcelFile(buffer: any, fileName: string): void {
  this.showLoading = true;
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
    this.showLoading = false;
}

print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'Change Control Board','trSearch',true);
}
}

