import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem, SortEvent} from 'primeng/api';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from '../../services/printcommon.service';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  styles: [
    `
      :host ::ng-deep .p-multiselect { 
        min-width: 15rem;
      }
      :host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
        min-width: 20rem;
      }
      :host ::ng-deep .multiselect-custom .p-multiselect-label {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
      :host ::ng-deep .multiselect-custom .country-item.country-item-value {
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
      }
      :host
        ::ng-deep
        .multiselect-custom
        .country-item.country-item-value
        img.flag {
        width: 17px;
      }
      :host ::ng-deep .multiselect-custom .country-item {
        display: flex;
        align-items: center;
      }
      :host ::ng-deep .multiselect-custom .country-item img.flag {
        width: 18px;
        margin-right: 0.5rem;
      }
      :host ::ng-deep .multiselect-custom .country-placeholder {
        padding: 0.25rem;
      }
      :host ::ng-deep .p-colorpicker {
        width: 2.5em;
      }
    `,
  ],
})
export class ReportsComponent implements OnInit {
  status: SelectItem[];
  cols: any[];
  issues:any;
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  changeRequestsData:any;
  requestedby:any[]=[];
  selectedUsersChecked:any[]=[];
  months: ({ Id: string; Name: string } | { Id: number; Name: string })[];
  monthselect: any;
  piedata:any;
  piedata2:any;
  options: any;
  isChartDataAvailable:boolean;
  StatusChartData:any;
  month:any;
  ChangeTypeoptions:any;
  ChangeTypeData:any;
  isChangeTypeDataAvailable:boolean;
  changeRequestData:any[]=[];
  selectedFromDate:any;
  selectedToDate:any;
  ShowCustom:boolean;
  selectedUser:any=[];
  constructor(private _facadeService: FacadeService,public datepipe: DatePipe, private datePipe: DatePipe,public printService: PrintcommonService,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
      this.cols = [
        { field: 'ecrn', header: 'ECRN #', dynamicPlaceHolder: 'Search' },
        { field: 'changeGroupName', header: 'Change Type', dynamicPlaceHolder: 'Search' },
        { field: 'changeItem', header: 'Change Item', dynamicPlaceHolder: 'Search' }, 
        { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' },  
        { field: 'additionalInfo', header: 'Additional Info', dynamicPlaceHolder: 'Search' },
        { field: 'owner', header: 'Requested By', dynamicPlaceHolder: 'Search' }, 
        { field: 'createdAt', header: 'Requested Date', dynamicPlaceHolder: 'Search' }
      ];
    this.months = [
      {
        Id: "",
        Name: "Select",
      },
      {
        Id: 0,
        Name: "Current Week",
      },
      {
        Id: 1,
        Name: "Previous Week",
      },
      {
        Id: 2,
        Name: "Current Month",
      },
      {
        Id: 3,
        Name: "Previous Month",
      },
      {
        Id: 4,
        Name: "Current Quarter",
      },
      {
        Id: 5,
        Name: "Previous Quarter",
      },
      {
        Id: 6,
        Name: "Custom",
      },
    ]; 
    this.month=this.months[1];
    if(sessionStorage.getItem('reportmonth') != null && sessionStorage.getItem('reportmonth') != undefined)
    {
      if(sessionStorage.getItem('reportmonth') != "")
      {
        this.month = this.months.find(x=>x.Name == sessionStorage.getItem('reportmonth'));
        this.ShowCustom=false;
        if(this.month != undefined && this.month != null)
        {
          if(this.month.length > 1)
          {
            if(this.month.Name=="Custom"){
              this.ShowCustom =true;
              if(sessionStorage.getItem('reportStartDate') != null && sessionStorage.getItem('reportStartDate') != undefined)
              {
                if(sessionStorage.getItem('reportStartDate') != "")
                {
                  this.selectedFromDate = this.datepipe.transform(sessionStorage.getItem('reportStartDate'), 'MM/dd/yyyy');
                }
              }
              if(sessionStorage.getItem('reportEndDate') != null && sessionStorage.getItem('reportEndDate') != undefined)
              {
                if(sessionStorage.getItem('reportEndDate') != "")
                {
                  this.selectedToDate = this.datepipe.transform(sessionStorage.getItem('reportEndDate'), 'MM/dd/yyyy');
                }
              }
            }
          }
        }
      }
    }
    this.getUsers();
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Change Requests','trSearch',true);
  }
  exportExcel() {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       this.delete_row(wb.Sheets.Sheet1, 1);
       ws['!cols'] = [{ wpx : 90 },{ wpx : 120 },{ wpx : 100 },{ wpx : 60 },{ wpx : 120 },{ wpx : 100},{ wpx : 100 }];
       /* save to file */
       XLSX.writeFile(wb, "Change Requests.xlsx");
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
selectedUsersChange(selectedUser){
  this.selectedUsersChecked=[];
  selectedUser.value.forEach((value, key) => {
    this.selectedUsersChecked.push(value.userGuid);
  });
}

getUsers() {
  this.showLoading = true;
  this._facadeService.getUsers().subscribe(
    (data) => {
      this.showLoading = false;
      data.returnObject.forEach((value, key) => {
        value.fullName= value.firstName + " "+value.lastName;
      });
      this.requestedby = data.returnObject;
      let ok = false;
      if(sessionStorage.getItem('reportuser') != null && sessionStorage.getItem('reportuser') != undefined)
      {
        if(sessionStorage.getItem('reportuser') != "")
        {
          ok = true;
          var projectstats = [];
          projectstats = sessionStorage.getItem('reportuser').split(',');
          projectstats.forEach((element) => {
            this.requestedby.forEach(element1 => {
              if(element1.userGuid == element)
              {
                this.selectedUser.push(element1);
                this.selectedUsersChecked.push(element1.userGuid);
              }
            });
          });
        }
      }
      if(!ok)
      {
        this.requestedby.forEach(element1 => {
            this.selectedUser.push(element1);
            this.selectedUsersChecked.push(element1.userGuid);
        });
      }
      this.GetReportData();
      console.log(this.requestedby);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

GetReportData(){
  this.changeRequestData =[];
  this.ChangeTypeData=[];
  this.StatusChartData =[];
  sessionStorage.reportuser = this.selectedUsersChecked;
  sessionStorage.reportmonth = this.month.Name;
  sessionStorage.reportStartDate = this.selectedFromDate
  sessionStorage.reportEndDate = this.selectedToDate
  var reportData={
    requestedUsers: this.selectedUsersChecked,
    period: this.month.Name=="Select"?"":this.month.Name,
    fromDate: this.selectedFromDate,
    toDate: this.selectedToDate
  }
  this.showLoading = true;
          this._facadeService.GetReportData(reportData).subscribe(
            (data) => {
              this.showLoading = false;
            
              if(data.returnObject.statusChartData!=null){
                this.getChartData(data.returnObject.statusChartData);
              }
              if(data.returnObject.changeTypetData!=null){
                this.getChangeTypetData(data.returnObject.changeTypetData);
              }
             
             if(data.returnObject.changeRequestData!=null){
              data.returnObject.changeRequestData.forEach((value, key) => {
               let convertactiondate = new Date(value.createdAt+'Z') 
                value.createdAt =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
                 this.changeRequestData.push(value);
                 value.createdAt = this.datePipe.transform(value.createdAt, 'MM/dd/yyyy');
              });
            }
            console.log("Response:"+JSON.stringify(data.returnObject));
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            } 
          );
}

getChartData(res) {
  let labels=[];
  let data=[];
  for (let i = 0; i < res.length; i++) {
    labels.push(res[i].status);
  }
  for (let i = 0; i < res.length; i++) {
    data.push(res[i].count);
    if(res[i].count>0){
      this.isChartDataAvailable = true;
    }
  }
  this.StatusChartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
          '#FBC02D',
          '#2196F3',
          '#689F38',
          '#FF0000'
          ],
      },
    ],
  };

  this.options = {
    legend: { display: true, position:'right', 
    labels: { fontColor: "#7b7c7c",fontSize: 14}
  },
}
}

getChangeTypetData(res) {
  let labels=[];
  let data=[];
  for (let i = 0; i < res.length; i++) {
    labels.push(res[i].status);
  }
  for (let i = 0; i < res.length; i++) {
    data.push(res[i].count);
    if(res[i].count>0){
      this.isChangeTypeDataAvailable = true;
    }
  }
  this.ChangeTypeData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
                     '#FBC02D',
            '#2196F3',
            '#689F38',
            '#607D8B',
            '#FF0000', 
            '#800080',  
          ],
      },
    ],
  };

  this.ChangeTypeoptions = {
    legend: { display: true, position:'right', 
    labels: { fontColor: "#7b7c7c",fontSize: 14}
  }
}
}

SelectPeriod(month){
  if(month.value.Name=="Custom"){
  this.ShowCustom =true;
  }
  else{
    this.ShowCustom=false;
  }
}
changeRequest(rowdata){
  if(rowdata.changeGroupId==1){
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'partchangerequest?id='+rowdata.changeRequestGuid+'&source=R'+'&status='+rowdata.statusId;
  }
  else{
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'projectchangerequest?id='+rowdata.changeRequestGuid+'&source=R'+'&status='+rowdata.statusId;
  }
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'createdAt') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.createdAt = this.datePipe.transform( e.createdAt, 'MM/dd/yyyy');
  });
}
}
