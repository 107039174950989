<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid" style="float: right;">
                <div class="p-field p-col-12 p-lg-1"></div>
                        <div class="p-field p-col-12 p-lg-3">
                            <label style="padding-right:10px; margin-left:10px;">
                                From Date:</label>
                            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedFromDate"></p-calendar>
                        </div>
                        <div class="p-field p-col-12 p-lg-3">
                            <label style="padding-right:10px; margin-left:10px;">
                                To Date:</label>
                            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedToDate"></p-calendar>
                        </div>
                        <div class="p-field p-col-12 p-lg-3">
                            <label style="padding-right:10px; margin-left:10px;">Status:</label>
                            <p-multiSelect [options]="status" [(ngModel)]="selectedStatus"
                                (onChange)="selectedStatusChange($event)" class="multiselect-custom"
                                defaultLabel="Select Status">
                                <ng-template let-value pTemplate="selectedItems">
                                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                                        <div>option.name</div>
                                    </div>
                                    <div *ngIf="!selectedMulti || selectedMulti.length === 0"
                                        class="country-placeholder">
                                        Select Status
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        <div class="p-field p-col-12 p-lg-2">
                    <a (click)="getChangeRequestsData()">
                        <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                            class="p-button-raised p-button-primary" style="margin-top: 25px;width: 100%;"></button>
                    </a>
                </div>
            </div>
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%; ">Change Requests</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                    <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
                        tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;height:25px; width:25px;"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()"
                        pTooltip="Download" tooltipPosition="top"
                        class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; height:25px; width:25px;"></button>
                </span>
            </div>
            <div class="invoice invoice-header" id="invoice-content">
                <p-table (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="changeRequestsData" id="excel-table" [columns]="cols"  [scrollable]="true" scrollHeight="380px"
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    dataKey="id"
                    [globalFilterFields]="['ecrn','changeGroupName','changeItem','additionalInfo','owner','createdAt','statusName']">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'cItem':i==2,'status': i == 6,'ecrn':i==0,'reqBy':i==4,'reqDate':i==5,'cType':i==1,
                                'additionalInfo':i==3}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th style="width:80px;">Actions</th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')"
                                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 150px;">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 160px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 270px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 250px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 150px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 5" style="text-align: center;width: 120px;">
                                    {{rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 6" style="text-align: left;padding-left:45px;width: 120px;">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                            <td style="text-align: center;width: 80px;">
                                <a (click)="ViewChangeRequest(rowData)"> <button pButton type="button" icon="pi pi-eye"
                                        pTooltip="view" tooltipPosition="left" class="p-button-raised p-button-primary"
                                        style="width:25px; height:25px;text-align:center;"></button>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align: left;">No records found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>