import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';

@Component({
  selector: 'app-approvalsneeded',
  templateUrl: './approvalsneeded.component.html',
  styleUrls: ['./approvalsneeded.component.scss'],
})
export class ApprovalsneededComponent implements OnInit {

  userId = sessionStorage.getItem('userGuid');
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
   cols:any;
   selectedDate:any;
  minDate:any;
   status: SelectItem[];
   selectedStatus:any[]=[];
   selectedStatusChecked:any[]=[];
   changeRequestData:any;
   showLoading:boolean;
   selectedFromDate:any;
   selectedToDate:any;
  constructor(
    private _facadeService: FacadeService,public printService: PrintcommonService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  ngOnInit(): void {
    this.selectedToDate=new Date();
    let dte = new Date();
    dte.setMonth(dte.getMonth() - 1);
    this.selectedFromDate= new Date(dte.toString());
    if(sessionStorage.getItem('AStartDate') != null && sessionStorage.getItem('AStartDate') != undefined)
    {
      if(sessionStorage.getItem('AStartDate') != "")
      {
        this.selectedFromDate = this.datePipe.transform(sessionStorage.getItem('AStartDate'), 'MM/dd/yyyy');
      }
    }
    if(sessionStorage.getItem('AEndDate') != null && sessionStorage.getItem('AEndDate') != undefined)
    {
      if(sessionStorage.getItem('AEndDate') != "")
      {
        this.selectedToDate = this.datePipe.transform(sessionStorage.getItem('AEndDate'), 'MM/dd/yyyy');
      }
    }
      this.cols = [
        { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'Search' },
      { field: 'changeGroupName', header: 'Change Type', dynamicPlaceHolder: 'Search' },      
      { field: 'changeItem', header: 'Change Item', dynamicPlaceHolder: 'Search' },
      { field: 'owner', header: 'Requested By', dynamicPlaceHolder: 'Search' },
      { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' }
      ];
      this.status = [ 
        {label: 'Waiting For Approval', value: {id: 1, name: 'WFA', code: 1}},
       {label: 'Approved', value: {id: 2, name: 'Approved', code: 2}},
        {label: 'Rejected', value: {id: 3, name: 'Rejected', code: 3}},
    ];
    var isFromDashboardTileStatus = this.route.snapshot.queryParamMap.get('status');
    if(isFromDashboardTileStatus!=null && isFromDashboardTileStatus!="" && isFromDashboardTileStatus!=undefined){
      var filteredStatus = this.status.find(m=>m.label==isFromDashboardTileStatus);
      this.selectedStatus.push(filteredStatus.value);
      this.selectedStatusChecked.push(Number(filteredStatus.value.code));
    }
    else{
      let ok = false;
      if(sessionStorage.getItem('AStatus') != null && sessionStorage.getItem('AStatus') != undefined)
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('AStatus').split(',');
        projectstats.forEach((element) => {
          this.status.forEach(element1 => {
            if(element1.value.code == element)
            {
              this.selectedStatus.push(element1.value);
              this.selectedStatusChecked.push(Number(element));
            }
          });
        });
        ok = true;
      }
      if(!ok)
      {
        this.selectedStatus.push(this.status[0].value)
        this.selectedStatusChecked.push(1);
        sessionStorage.AStatus = this.selectedStatusChecked;
      }
    }
      this.GetApprovals();
  }

  GetApprovals(){
    this.showLoading = true;
    sessionStorage.AStartDate = this.selectedFromDate;
    sessionStorage.AEndDate = this.selectedToDate;
    sessionStorage.AStatus = this.selectedStatusChecked;
    var model={
      UserId:this.userId,
      statusIds:this.selectedStatusChecked,
      FromDate:this.datePipe.transform(this.selectedFromDate, "MM/dd/yyyy"),
      ToDate:this.datePipe.transform(this.selectedToDate, "MM/dd/yyyy")
    }
    this._facadeService.GetApprovals(model).subscribe(
      (data) => {
        this.changeRequestData = data.returnObject;
        console.log("Approvals data:"+JSON.stringify(this.changeRequestData));
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

 
ChangeStatus(selectedStatus){
  this.selectedStatusChecked=[];
  selectedStatus.forEach((value, key) => {
    this.selectedStatusChecked.push(value.code);
  });
}
  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 90 },{ wpx : 100 },{ wpx : 110 },{ wpx : 90 },{ wpx : 80 }];
     /* save to file */
     XLSX.writeFile(wb, "My Approval Needed.xlsx")
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['F1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
 
print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'My Approval Needed','trSearch',true);
}
ViewChangeRequest(rowdata){
  if(rowdata.changeGroupId==1){
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'partchangerequest?id='+rowdata.changeRequestGuid+'&source=AN'+'&CCBApproved='+rowdata.ccbApproved;
  }
  else{
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'projectchangerequest?id='+rowdata.changeRequestGuid+'&source=AN'+'&CCBApproved='+rowdata.ccbApproved;
  }
}
}

