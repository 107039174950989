<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-fluid p-formgrid p-grid">
                <div class=" p-col-12 p-lg-10 p-md-12 p-sm-12"></div>
                <div class=" p-col-12 p-lg-2 p-md-12 p-sm-12">                
                    <button routerLink="/admin/createccbcontrol" pButton pRipple type="button" icon="pi pi-plus"
                        iconPos="left" label="New" class="p-button-raised p-button-primary "
                        [disabled]="userFeature"
                        style="width:100%; float:right;margin-bottom: 10px;"></button>               
                </div>
            </div>
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">

                <h5 style="color:#495057; padding:15px 0 0 15px;width:80%;">Admin / Change Control Board</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                    <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
                        tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;height:25px; width:25px;"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()"
                        pTooltip="Download" tooltipPosition="top"
                        class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; height:25px; width:25px;"></button>
                </span>
            </div>
            <div class="invoice invoice-header" id="invoice-content" >
                <p-table #dt [value]="ccbData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="200px"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
                    [globalFilterFields]="['boardName','prodctLine']">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                                [ngClass]="{'name':i==0,'productName': i == 1}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th style="width:70px;">Actions</th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')"
                                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:20px;width: 550px;">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:40px;width: 550px;">
                                    {{ rowData[col.field] }}
                                </td>
                            </ng-container>
                            <td style="text-align: center;width:70px;">
                                <button pButton type="button" (click)="EditCCBDetails(rowData)" icon="pi pi-pencil"
                                    pTooltip="Edit" tooltipPosition="right" class="p-button-raised p-button-primary"
                                    [disabled]="userFeature"
                                    style="width:25px; height:25px;text-align:center;"></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3" style="text-align: left;">No data found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

        </div>
            </div>
        </div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>