import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _httputilityService: HttputilityService,
    private _http:HttpClient
    ) {}

  getDocTypes(){
    return this._httputilityService.Get('GetDocumentTypes');
  }

  getDepartMents(){
    return this._httputilityService.GetU('user/GetDepartments'); 
  }

  getRolesByDepartmentGuid(departmentGuid){
    debugger;
    return this._httputilityService.GetU(`user/GetRolesByDepartment?departmentGuid=${departmentGuid}`);
  }
  
  getRolesByDepartmentGuids(departmentGuids){
    debugger;
    //return this._httputilityService.GetU(`user/GetRolesByDepartments?departmentGuid=${departmentGuids}`);
    return this._httputilityService.PostU("user/GetRolesByDepartments", departmentGuids);
  }

  GetUsersByRoleGuid(RoleGUID){
    debugger;
    return this._httputilityService.GetU(`user/GetUsersByRole?RoleGUID=${RoleGUID}`);
  }

  getUsers(){
    return this._httputilityService.GetU('user/GetUsers');
  }
  GetProductLines(){
    return this._httputilityService.GetPDM('CustomFields/GetData?Query=select * from tblproductline');
  }
}
