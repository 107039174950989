import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { PrintcommonService } from '../../services/printcommon.service';
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem, SortEvent} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-createnewrequest',
  templateUrl: './createnewrequest.component.html',
  styleUrls: ['./createnewrequest.component.scss']
})
export class CreatenewrequestComponent implements OnInit {
  userId = sessionStorage.getItem('userGuid');
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  cols:any;
  status:any;
  selectedStatus: any;
  ShowProjects:boolean;
  ShowParts:boolean=true;
  PartData:any;
  ProjectData:any;
  ProjectData1:any;
  userFeature:boolean=false;
  constructor(
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
    private _facadeService: FacadeService,public printService: PrintcommonService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
  ) {}
  ngOnInit(): void { 
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="CMS_PRJD");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;
      }
    }
    this.status = [ 
      {label: 'Product', value: {id: 0, name: 'Product', code: 1}},
      {label: 'Project', value: {id: 1, name: 'Project', code: 2}}
  ];
     var Urlstatus = this.route.snapshot.paramMap.get('S');
    if(Urlstatus!=null && Urlstatus!="" && Urlstatus!=undefined){
      var filteredStatus = this.status.find(m=>m.label==Urlstatus);
      this.selectedStatus = filteredStatus.value;
      this.ChangeStatus(this.selectedStatus);
    }
    else{
      this.selectedStatus=this.status[0].value;
      this.ChangeStatus(this.selectedStatus);
    }
  }

  ChangeStatus(selectedStatus){
if(selectedStatus.code==1){
  this.PartReqClick();
}
else if(selectedStatus.code==2){
  this.ProjectReqClick();
}
  }

  GetPartDetails(){
    this.cols = [
      { field: 'productName', header: 'Product', dynamicPlaceHolder: 'Search' }, 
      { field: 'mfgPart', header: 'MFG Part Number', dynamicPlaceHolder: 'Search' },
      { field: 'dieCode', header: 'Die Code', dynamicPlaceHolder: 'Search' },
      { field: 'productOwner', header: 'Product Owner', dynamicPlaceHolder: 'Search' },
      { field: 'phaseStatus', header: 'Status', dynamicPlaceHolder: 'Search' },
        { field: 'phase', header: 'Active Phase', dynamicPlaceHolder: 'Search' }
    ];
    this.showLoading = true;
    this._facadeService.GetPartDetails(this.userId,"Active").subscribe(
      (data) => {
        this._facadeService.GetChangeRequestProjectProductsByUser(this.userId,1).subscribe(
          (res) => {
            if(data.returnObject.length>0){
              var partdataa = [];
              data.returnObject.forEach((value, key) => {
                var partdetails = res.returnObject.find(m=>m.itemGuid==value.productGuid);
                if(partdetails==null ||partdetails==undefined||partdetails==""){
                  partdataa.push(value);
                }
              });
              this.PartData = partdataa;
            }
            else
              this.PartData = data.returnObject;
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          } 
        );
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetProjectDetails(){
    this.showLoading = true;
    this.cols = [
      { field: 'projectName', header: 'Project Name', dynamicPlaceHolder: 'Search' }, 
      { field: 'phaseName', header: 'Current Phase', dynamicPlaceHolder: 'Search' },
      { field: 'projectManagerName', header: 'Product Engineer', dynamicPlaceHolder: 'Search' },
      { field: 'projectStartDate', header: 'Start Date', dynamicPlaceHolder: 'Search' },
      { field: 'targetDate', header: 'End Date', dynamicPlaceHolder: 'Search' }
    ];
    var type = 'false';
    
    if(this.userFeature)
      type = 'true';
    this._facadeService.GetProjectDetails(type,this.userId).subscribe(
      (data) => {
        this.ProjectData1 = []; this.ProjectData = [];
        if(data.returnObject != null &&  data.returnObject != undefined)
        {
          if(data.returnObject.length > 0)
          {
            this._facadeService.GetChangeRequestProjectProductsByUser(this.userId,2).subscribe(
            // this._facadeService.GetChangeRequestProjects(2).subscribe(
            (res) => {
              var projects = []; var projects1 = [];
              if(res.returnObject != null && res.returnObject != undefined)
              {
                if(res.returnObject.length > 0)
                {
                  data.returnObject.forEach((value) => {
                    var projectdetails = res.returnObject.find(m=>m.itemGuid==value.projectId);
                    if(projectdetails==null ||projectdetails==undefined||projectdetails==""){
                      projects.push(value);
                      value.projectStartDate = this.datePipe.transform(value.projectStartDate, 'MM/dd/yyyy');
                      value.targetDate = this.datePipe.transform(value.targetDate, 'MM/dd/yyyy');
                    }
                    else
                    {
                      projects1.push(value);
                      value.projectStartDate = this.datePipe.transform(value.projectStartDate, 'MM/dd/yyyy');
                      value.targetDate = this.datePipe.transform(value.targetDate, 'MM/dd/yyyy');
                    }
                  });
                  this.ProjectData = projects;
                  this.ProjectData1 = projects1;
                }
                else
                  this.ProjectData = data.returnObject;
              }
              else
                this.ProjectData = data.returnObject;
              this.showLoading = false;
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            } 
            );
          }
          else 
            this.showLoading = false;
        }
        else
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  PartChangeRequest(rowdata){
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'partchangerequest?partId='+rowdata.productID+'&productLine='+rowdata.productLine;
  }
  ProjectChangeRequest(rowdata){
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'projectchangerequest?prjId='+rowdata.projectId;
  }
  
  ProjectReqClick(){
    this.ShowProjects=true;
    this.ShowParts=false;
    this.GetProjectDetails();
  }

  PartReqClick(){
    this.ShowProjects=false;
    this.ShowParts=true;
    this.GetPartDetails();
  }
delete_row (ws, row_index,ID){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  if(ID=="excel-Products")
    delete (ws['G1'])
  else
    delete (ws['F1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
exportExcel(ID,title){
  /* table id is passed over here */   
 let element = document.getElementById(ID); 
 const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
 /* generate workbook and add the worksheet */
 const wb: XLSX.WorkBook = XLSX.utils.book_new();
 XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 this.delete_row(wb.Sheets.Sheet1, 1,ID);
 ws['!cols'] = [{ wpx : 150 },{ wpx : 130 },{wpx : 500 },{ wpx : 140 },{ wpx : 130 },{ wpx : 130 }];
 /* save to file */
 XLSX.writeFile(wb,title+ ".xlsx");
}

saveAsExcelFile(buffer: any, fileName: string): void {
  this.showLoading = true;
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
    this.showLoading = false;
}

print(ID,SearchID,title) {
  var Content = document.getElementById(ID).innerHTML;
  this.printService.Print(Content,title,SearchID,true);
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'projectStartDate'||event.field === 'targetDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.projectStartDate = this.datePipe.transform(e.projectStartDate, 'MM/dd/yyyy');
    e.targetDate = this.datePipe.transform(e.targetDate, 'MM/dd/yyyy');
  });
}

}