import {Component, Inject} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {AppMainComponent} from './app.main.component';
import { BASE_API_URL_TOKEN } from './injectors';

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightmenuComponent{

    amount: SelectItem[];
    // megaMenuItems: MegaMenuItem[];
    selectedAmount: any;

    constructor(public app: AppMainComponent,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.amount = [
            {label: '*****24', value: {id: 1, name: '*****24', code: 'A1'}},
            {label: '*****75', value: {id: 2, name: '*****75', code: 'A2'}}
        ];
	
}
goToLinkProject(){
    window.open(this.baseUrl["API_ENDPOINTS"]["ProjectUI"], "_blank");
    }
    goToLinkProduct(){
        window.open(this.baseUrl["API_ENDPOINTS"]["ProductUI"], "_blank");
        }
}
