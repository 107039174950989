import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DashboardComponent implements OnInit {
  userId = sessionStorage.getItem('userGuid');
  labels: number[] = [];
  data: number[] = [];
  timelineSubTitle: any;
  
  options: any;
  plugin: any;
  Timeline: any;
  TimelineOptions: any;
  selectedTimelineOption: any;
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading:boolean;
  dashBoardCounts:any;
  documentChart:any;
  changeRequestData:any=[];
  isTimelineDataAvailable:boolean;
  isChartDataAvailable:boolean;
  dashBoardCountsNew:Int16Array;
  dashBoardCountsInprocess:Int16Array;
  dashBoardCountsPending:Int16Array;
  dashBoardCountsApproved:Int16Array;
  dashBoardCountsProducts:Int16Array;
  dashBoardCountsProjects:Int16Array;
  dashBoardCountsDocuments:Int16Array;
  constructor(
    private _facadeService: FacadeService,public printService: PrintcommonService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  ngOnInit(): void {
    var guid = this.route.snapshot.queryParamMap.get('userguid');
    if(guid!='' && guid!=undefined){
      this.userId= guid;
      sessionStorage.userId=guid;
    }
    this.TimelineOptions = [
      { name: 'Week',value: 7 },
      { name: '2 Weeks',value: 14 },
      { name: '3 Weeks',value: 21 },
      { name: 'Month',value: 30 },
    ];
    this.cols = [
      { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'Search' },
      { field: 'changeGroupName', header: 'Change Type', dynamicPlaceHolder: 'Search' },      
      { field: 'changeItem', header: 'Change Item', dynamicPlaceHolder: 'Search' },
      { field: 'additionalInfo', header: 'Additional Info', dynamicPlaceHolder: 'Search' },
      { field: 'owner', header: 'Requested By', dynamicPlaceHolder: 'Search' },
      { field: 'createdAt', header: 'Requested Date', dynamicPlaceHolder: 'Search' },
        { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' }
    ];
      this.getDashboardSummary();
      this.getChartData();
      this.getDashboardTimeline(7);
      this.getDashboardTableData();
  }
  getDashboardSummary() {
    this.showLoading = true;
    this._facadeService.getDashboardCounts(this.userId).subscribe(
      (data) => {
        this.dashBoardCounts = data.returnObject;
        this.dashBoardCountsNew=this.dashBoardCounts.new;
        this.dashBoardCountsInprocess=this.dashBoardCounts.inProcess;
        this.dashBoardCountsPending=this.dashBoardCounts.approvalPending;
        this.dashBoardCountsApproved=this.dashBoardCounts.approvalCompleted;
        this.dashBoardCountsDocuments=this.dashBoardCounts.documents;
        this.dashBoardCountsProducts=this.dashBoardCounts.products;
        this.dashBoardCountsProjects=this.dashBoardCounts.projects;
        this.showLoading = false;
        console.log("dashboard count:"+JSON.stringify(this.dashBoardCounts));
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  getDashboardTableData() {
    this.changeRequestData=[];
    this.showLoading = true;
    this._facadeService.GetDashboardTableData30Days(this.userId).subscribe(
      (data) => {
        this.showLoading = false;
        console.log(data);
        if(data.returnObject!=null){
          data.returnObject.forEach((value, key) => {
           let convertactiondate = new Date(value.createdAt+'Z') 

            value.createdAt =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
            value.createdAt = this.datePipe.transform(value.createdAt, 'MM/dd/yyyy');
             this.changeRequestData.push(value);
          });
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  GetTimeline(data){
    this.getDashboardTimeline(data.value)
  }
  getDashboardTimeline(type) {
    this.showLoading = true;
    this._facadeService.getDashboardTimeline(this.userId, type).subscribe(
      (data) => {
        this.showLoading = false;
        this.Timeline = data.returnObject;  
        if( this.Timeline.length>0) {  
          this.isTimelineDataAvailable = true;   
        var dict = []; // create an empty dictionary  
        for (let i = 0; i < this.Timeline.length; i++) { 
          let convertactiondate = new Date(new Date(this.Timeline[i].actionDate).toDateString() + " " + new Date(this.Timeline[i].actionDate).toTimeString() + " UTC");
          dict.push({  
            changeRequestCode: this.Timeline[i].changeRequestCode,
            title: this.Timeline[i].title,
            subTitle: this.Timeline[i].subTitle,
            icon: this.Timeline[i].icon, 
            actionBy: this.Timeline[i].actionBy,
            actionDate: this.Timeline[i].actionDate,
            changeRequestGuid: this.Timeline[i].changeRequestGuid,
            changeGroupId: this.Timeline[i].changeGroupId,
            statusId: this.Timeline[i].statusId,
            subTitleMod: this.sanitizer.bypassSecurityTrustHtml("The change request " + '<a style="cursor:pointer;" (click)="changeRequest()">' + this.Timeline[i].changeRequestCode + '</a>' +" "+this.Timeline[i].subTitle+convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString())  
        });        
        }        
        console.log("Time Line subtitle modified data Data :: " + JSON.stringify(dict));
        this.Timeline = dict;  
      } 
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
        const activity = parseInt(value);

        if (!isNaN(activity)) {
            this.table.filter(activity, 'activity', 'gte');
        }
    }
}

  getChartData() {
    this.showLoading = true;
    this._facadeService.getChartData(this.userId).subscribe(
      (res) => {
        for (let i = 0; i < res.returnObject.length; i++) {
          this.labels.push(res.returnObject[i].status);
        }
        for (let i = 0; i < res.returnObject.length; i++) {
          this.data.push(res.returnObject[i].count);
          if(res.returnObject[i].count>0){
            this.isChartDataAvailable = true;
          }
        }
        console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
        this.documentChart = {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: [
                '#FBC02D',
                '#2196F3',
                '#689F38',
                '#FF0000',
                '#607D8B',
                ],
            },
          ],
        };

        this.options = {
          legend: { display: true, position:'right', align:'center', 
          labels: { fontColor: "#7b7c7c",fontSize: 14}
        },
      }
      this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Recent Change Requests','trSearch',true);
  }

  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 90 },{ wpx : 110 },{ wpx : 80 },{ wpx : 110 },{ wpx : 140 },{ wpx : 90 },{ wpx : 60 }];
     /* save to file */
     XLSX.writeFile(wb, "Recent Change Requests.xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}


changeRequest(rowdata){
  if(rowdata.changeGroupId==1){
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'partchangerequest?id='+rowdata.changeRequestGuid+'&source=D'+'&status='+rowdata.statusId;
  }
  else{
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'projectchangerequest?id='+rowdata.changeRequestGuid+'&source=D'+'&status='+rowdata.statusId;
  }
}

ChangeBreakUp(){
  location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'changerequests';
}

TileClick(status){
  location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'changerequests?status='+status;
}
TileClickApproval(status){
  location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'approvalsneeded?status='+status;
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'createdAt') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.createdAt = this.datePipe.transform( e.createdAt, 'MM/dd/yyyy');
  });
}
}
