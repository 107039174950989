<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="p-grid p-formgrid">
            <div class="p-field p-col-12 p-lg-9 p-md-12 p-sm-12"></div>
      </div>
      <div class="card-header" *ngIf="ShowParts"
        style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">

        <h5 style="color:#495057; padding:15px 0 0 15px; width:60%">Products</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
          <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content','trSearch','Products')" pTooltip="Print"
            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel('excel-Products','Products')" pTooltip="Download"
            tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>
        </span> 
      </div>
      <div class="invoice invoice-header" id="invoice-content"  *ngIf="ShowParts" >
        <p-table #dt [value]="PartData" id="excel-Products" [columns]="cols" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="200px"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
          [globalFilterFields]="['code','title','documentType','owner','status']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                [ngClass]="{'productName': i == 0,'mfgPart': i == 1, 'dieCode': i == 2, 'productOwner': i ==3,
                'phasestatus':i == 4,'phase':i == 5 }">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width:80px;">Change Request</th>
            </tr>
            <tr id="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                  (input)="dt.filter($event.target.value, col.field, 'contains')"
                  style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
              </th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 200px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 400px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 140px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 140px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 140px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 5" style="text-align: center;width: 120px;">
                  {{rowData[col.field]}}
                </td>
              </ng-container>
              <td style="text-align: center;width: 80px;">
                <a (click)="PartChangeRequest(rowData)">
                  <button pButton type="button" icon="pi pi-plus" pTooltip="Change Request" tooltipPosition="right"
                    class="p-button-raised p-button-primary" style="height:25px;width:25px;"></button>
                </a>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7" style="text-align: left;">No data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="invoice invoice-header" style="min-height:600px;" *ngIf="ShowProjects">

        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">

          <h5 style="color:#495057; padding:15px 0 0 15px; width:60%">Projects</h5>
          <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content1','trSearch1','Projects')" pTooltip="Print"
            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel('excel-Projects','Projects')" pTooltip="Download"
            tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>
          </span>
        </div>
        <div class="invoice invoice-header" id="invoice-content1">
          <p-table (sortFunction)="customSort($event)" [customSort]="true"  #dt [value]="ProjectData" id="excel-Projects" [columns]="cols"  [scrollable]="true" scrollHeight="200px"
            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
            [globalFilterFields]="['code','title','documentType','owner','status']">
            <ng-template pTemplate="header" let-columns class="invoice-items">
              <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                  [ngClass]="{'projectName':i==0,'phaseName': i == 1,'projectManagerName':i == 2,'projectStartDate': i == 3,
                  'targetDate':i == 4}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th style="width:80px;">Change Request</th>
              </tr>
              <tr id="trSearch1">
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                    (input)="dt.filter($event.target.value, col.field, 'contains')"
                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                </th>
                <th></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                  <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 250px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 1" style="text-align: center;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 3" style="text-align:center;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 4" style="text-align: center;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                  <!-- <td *ngIf="i == 5" style="text-align: center">
                    {{rowData[col.field]}}
                  </td> -->
                </ng-container>
                <td style="text-align: center;width: 80px;">
                  <a (click)="ProjectChangeRequest(rowData)">
                    <button pButton type="button" icon="pi pi-plus" pTooltip="Change Request" tooltipPosition="right"
                      class="p-button-raised p-button-primary" style="height:25px;width:25px;"></button>
                  </a>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6" style="text-align: left;">No data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;" *ngIf="ProjectData1?.length > 0">
          <h5 style="color:#495057; padding:15px 0 0 15px; width:60%">Active Change Request</h5>
        </div>
        <div class="invoice invoice-header" id="invoice-content2" *ngIf="ProjectData1?.length > 0">
          <p-table (sortFunction)="customSort($event)" [customSort]="true"  #dt1 [value]="ProjectData1" [columns]="cols"  [scrollable]="true" scrollHeight="200px"
            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id">
            <ng-template pTemplate="header" let-columns class="invoice-items">
              <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                  [ngClass]="{'projectName':i==0,'phaseName': i == 1,'projectManagerName':i == 2,'projectStartDate': i == 3,
                  'targetDate':i == 4}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                    (input)="dt1.filter($event.target.value, col.field, 'contains')"
                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                  <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 250px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 1" style="text-align: center;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 3" style="text-align:center;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 4" style="text-align: center;width: 200px;">
                    {{rowData[col.field]}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" style="text-align: left;">No data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>