import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private _httputilityService: HttputilityService) {}

  getDashboardCounts(userID) {
    return this._httputilityService.Get("GetDashboardSummary_CMS?userId=" + userID);
  }

  getDashboardTableData(model) {
    return this._httputilityService.Add("MyDocuments", model);
  }

  getChartData(userId) {
    return this._httputilityService.Get("GetChartData_CMS?userId=" + userId);
  }

  getDashboardTimelineData(userId,type) {
    return this._httputilityService.Get("GetTimeLineData_CMS?userId="+userId+"&timeLineType="+type);
  }

  GetDashboardTableData30Days(userId){
    return this._httputilityService.Get("GetDashboardTableData_CMS?userId=" + userId );
  }

  getDashboardDataFor30Days(userId) {
    return this._httputilityService.Get("GetDashboardData?userId=" + userId );
  }

  GetUserDetails(userGuid){
    return this._httputilityService.GetU("user/GetUserDetails?UserGUID=" + userGuid );
  }
  mydocumentsData(model) {
    return this._httputilityService.Add("MyDocuments", model);
  }
  GetCMSECODocuments(userId){
    return this._httputilityService.GetDMS("GetCMSECODocuments?userId=" + userId );
  }
  GetCMSRevisionDocuments(userId){
    return this._httputilityService.GetDMS("GetCMSRevisionDocuments?userId=" + userId );
  }
  GetResubmitDocumentDetails(userId){
    return this._httputilityService.GetDMS("GetResubmitDocumentDetails?userId=" + userId );
  }
}
