<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="p-fluid p-formgrid p-grid">
        <div class=" p-col-12 p-md-2">
          <a (click)="BackClick()"> <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left"
              label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width:auto;"></button></a>
        </div>
        <div class=" p-col-12 p-md-8" style="text-align:center;">
          <h4 style="margin-top:5px;">Project Change Request</h4>
        </div>
        <div class=" p-col-12 p-md-2">
          <span
            style="font-weight: bold;color:#1976d2;font-size: 16px;float: right;margin-top: 10px;margin-right: 10px;">
            <a (click)="ViewCCB()" style="cursor: pointer">View CCB</a></span>
        </div>
      </div>

      <hr /><br>
      <div class="card">
        <h4 style="margin-top:5px;">Change Request Details</h4>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-4">
            <label>ECRN</label>
            <input type="text" pInputText [(ngModel)]="ECRN" placeholder="Request Id #" [disabled]="true" style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>Status</label>
            <input type="text" pInputText placeholder="Status" [(ngModel)]="PrjStatus" [disabled]="true" style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>Change Type</label>
            <input type="text" pInputText placeholder="Change Type" [(ngModel)]="ChangeType" [disabled]="true" style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>Change Request Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>Change Description<span style="color: red">*</span></label>
            <textarea type="text" rows="2" [maxLength]="300" pInputTextarea id="Description" [(ngModel)]="Description" [disabled]="isReadOnly"></textarea>
            <small class="p-invalid">{{300 - Description.length}} characters are remaining</small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>Reasons<span style="color: red">*</span></label>
            <textarea id="BusinesBenifits" type="text" rows="2" [maxLength]="300" pInputTextarea [(ngModel)]="BusinesBenifits" [disabled]="isReadOnly"></textarea>
            <small class="p-invalid">{{300 - BusinesBenifits.length}} characters are remaining</small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>Justification / Business Benifits</label>
            <textarea id="Justification" type="text" rows="2" [maxLength]="300" pInputTextarea [(ngModel)]="Justification" [disabled]="isReadOnly"></textarea>
            <small class="p-invalid">{{300 - Justification.length}} characters are remaining</small>
          </div>
          <div class="p-field p-col-12 p-md-4" *ngIf="PrjStatus!='New' && commentsList.length > 0">
            <label>Comments</label>&nbsp;&nbsp;
            <button pButton pRipple type="button" icon="pi pi-eye" pTooltip="View" tooltipPosition="top"
              class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="height: 28px; width: 28px"
              (click)="commentsDisplay = true"></button>
          </div>
        </div>
      </div>
      <div class="card">
        <h4 style="margin-top:5px;">Project Details</h4>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <label>Project Name:&nbsp;&nbsp;</label>
            <input type="text" pInputText [(ngModel)]="projectName" placeholder="Name" disabled />
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label>Project Manager:&nbsp;&nbsp;</label>
            <input type="text" pInputText placeholder="Manager" [(ngModel)]="ProdOwner" [disabled]="true" style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label>Active Phase:&nbsp;&nbsp;</label>
            <input type="text" pInputText placeholder="Active Phase" [(ngModel)]="ActivePhase" [disabled]="true" style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label>Description:&nbsp;&nbsp;</label>
            <textarea type="text" rows="2" pInputTextarea [(ngModel)]="ItemDescription" disabled></textarea>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label>Project Target Start Date:&nbsp;&nbsp;</label>
            <input type="text" pInputText [(ngModel)]="ProjectStartDate" placeholder="Project Target Start Date" disabled />
          </div>
          <div class="p-field p-col-12 p-md-3" *ngIf="isProjectStartDateEdit">
            <label>Project Proposed Target Start Date:&nbsp;&nbsp;</label>
            <p-calendar [showIcon]="true" placeholder="End Date" inputId="plannedEndDate" [(ngModel)]="ProposedProjectStartDate" [disabled]="isReadOnly"
            (onClickOutside)="Getdates()" (onSelect)="Getdates('P','')" [disabledDays]="[0,6]"></p-calendar>
          </div>
        </div>
      </div>
      <div class="card">
        <h4 style="margin-top:5px;">Milestones</h4>
        <hr>
        <div style="overflow-x: auto;">
         <div style="min-height:200px;margin-top: -6px;border: 2px solid #e9ecef;"> 
          <p-table [value]="TaskData" dataKey="taskId" styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:40px;">Seq</th>
                    <th style="width:150px;">Milestone</th>
                    <th style="width:80px;">Target Start Date</th>
                    <th style="width:80px;">Target End Date</th>
                    <th style="width:80px;">Current Start Date</th>
                    <th style="width:80px;">Current End Date</th>
                    <th style="width:80px;">Proposed Target Start Date</th>
                    <th style="width:130px;">Proposed Target End Date</th>
                    <th style="width:80px;">Proposed Current Start Date</th>
                    <th style="width:130px;">Proposed Current End Date</th>
                    <!-- <th style="width:50px;">Reason</th> -->
                    <th style="width:60px;">Cycle Time</th>
                    <th style="width:60px;">Overlap Days</th>
                    <th style="width:120px;">Predecessor</th>
                    <th style="width:60px;">Is Weekend</th>
                    <th style="width:60px;">Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-mileStone>
                <tr>
                    <td style="text-align: center;width: 40px;">{{mileStone.taskSeq}}</td>
                    <td style="padding-left: 15px;width:150px;">{{mileStone.taskName}}</td>
                    <td style="text-align: center;width:80px;" [ngStyle]="{'background': mileStone.targetOverride === true ? '#ffe5b4': '','font-weight' : mileStone.targetOverride === true ? 'bold': ''}">
                      <span *ngIf="mileStone.targetStartDate != '--' && mileStone.targetStartDate != null && mileStone.duration != 0">{{mileStone.targetStartDate | date:'MM/dd/yyyy'}}</span>
                      <span *ngIf="mileStone.targetStartDate == '--' || mileStone.targetStartDate == null || mileStone.duration == 0">--</span>
                    </td>
                    <td style="text-align: center;width:80px;" [ngStyle]="{'background': mileStone.targetOverride === true ? '#ffe5b4': '','font-weight' : mileStone.targetOverride === true ? 'bold': ''}">
                      <span *ngIf="mileStone.targetEndDate != '--' && mileStone.targetEndDate != null && mileStone.duration != 0">{{mileStone.targetEndDate | date:'MM/dd/yyyy'}}</span>
                      <span *ngIf="mileStone.targetEndDate == '--' || mileStone.targetEndDate == null || mileStone.duration == 0">--</span>
                    </td>
                    <td style="text-align: center;width:80px;" [ngStyle]="{'background': mileStone.currentOverride === true ? '#ffe5b4': '','font-weight' : mileStone.currentOverride === true ? 'bold': ''}">
                      <span *ngIf="mileStone.currentStartDate != '--' && mileStone.currentStartDate != null && mileStone.duration != 0">{{mileStone.currentStartDate | date:'MM/dd/yyyy'}}</span>
                      <span *ngIf="mileStone.currentStartDate == '--' || mileStone.currentStartDate == null || mileStone.duration == 0">--</span>
                    </td>
                    <td style="text-align: center;width:80px;" [ngStyle]="{'background': mileStone.currentOverride === true ? '#ffe5b4': '','font-weight' : mileStone.currentOverride === true ? 'bold': ''}">
                      <span *ngIf="mileStone.currentEndDate != '--' && mileStone.currentEndDate != null && mileStone.duration != 0">{{mileStone.currentEndDate | date:'MM/dd/yyyy'}}</span>
                      <span *ngIf="mileStone.currentEndDate == '--' || mileStone.currentEndDate == null || mileStone.duration == 0">--</span>
                    </td>

                    <td style="text-align: center;width:80px;" [ngStyle]="{'background': mileStone.proposedTargetOverride === true ? '#ffe5b4': '','font-weight' : mileStone.proposedTargetOverride === true ? 'bold': ''}">
                        <span *ngIf="mileStone.newTargetStartDate != '--' && mileStone.newTargetStartDate != null && mileStone.duration != 0">{{mileStone.newTargetStartDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="mileStone.newTargetStartDate == '--' || mileStone.newTargetStartDate == null || mileStone.duration == 0">--</span>
                    </td>
                    <td style="text-align: center;width:130px;" [ngStyle]="{'background': mileStone.proposedTargetOverride === true ? '#ffe5b4': '','font-weight' : mileStone.proposedTargetOverride === true ? 'bold': ''}">
                        <span *ngIf="mileStone.newTargetEndDate != '--' && mileStone.newTargetEndDate != null && mileStone.duration != 0 && mileStone.isTargetEdit">
                            <p-calendar [showIcon]="true" placeholder="End Date" [(ngModel)]="mileStone.newTargetEndDate" [disabledDays]="mileStone.isWeekend == false ? [0,6] : ''"
                            [disabled]="mileStone.duration == 0 || isReadOnly" (onClickOutside)="Getdates('TT',mileStone.taskId)" (onSelect)="Getdates('TT',mileStone.taskId)"></p-calendar>
                        </span>
                        <span *ngIf="mileStone.newTargetEndDate != '--' && mileStone.newTargetEndDate != null && mileStone.duration != 0 && !mileStone.isTargetEdit">{{mileStone.newTargetEndDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="mileStone.newTargetEndDate == '--' || mileStone.newTargetEndDate == null || mileStone.duration == 0">--</span>
                    </td>
                    <!-- <td style="text-align: center;width:50px;">
                      <p-checkbox binary="mileStone.proposedTargetOverride" [(ngModel)]="mileStone.proposedTargetOverride" [disabled]="mileStone.proposedTargetOverride || mileStone.duration == 0 || !mileStone.isTargetEdit"
                      [value]="mileStone.proposedTargetOverride" (click)="Getdates()"></p-checkbox>
                    </td> -->

                    <td style="text-align: center;width:80px;" [ngStyle]="{'background': mileStone.proposedCurrentOverride === true ? '#ffe5b4': '','font-weight' : mileStone.proposedCurrentOverride === true ? 'bold': ''}">
                        <span *ngIf="mileStone.newCurrentStartDate != '--' && mileStone.newCurrentStartDate != null && mileStone.duration != 0">{{mileStone.newCurrentStartDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="mileStone.newCurrentStartDate == '--' || mileStone.newCurrentStartDate == null || mileStone.duration == 0">--</span>
                    </td>
                    <td style="text-align: center;width:130px;" [ngStyle]="{'background': mileStone.proposedCurrentOverride === true ? '#ffe5b4': '','font-weight' : mileStone.proposedCurrentOverride === true ? 'bold': ''}">
                        <span *ngIf="mileStone.newCurrentEndDate != '--' && mileStone.newCurrentEndDate != null && mileStone.duration != 0 && mileStone.isCurrentEdit">
                            <p-calendar [showIcon]="true" placeholder="End Date" [(ngModel)]="mileStone.newCurrentEndDate" [disabledDays]="mileStone.isWeekend == false ? [0,6] : ''"
                            [disabled]="mileStone.duration == 0 || isReadOnly" (onClickOutside)="Getdates('TC',mileStone.taskId)" (onSelect)="Getdates('TC',mileStone.taskId)"></p-calendar>
                        </span>
                        <span *ngIf="mileStone.newCurrentEndDate != '--' && mileStone.newCurrentEndDate != null && mileStone.duration != 0 && !mileStone.isCurrentEdit">{{mileStone.newCurrentEndDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="mileStone.newCurrentEndDate == '--' || mileStone.newCurrentEndDate == null || mileStone.duration == 0">--</span>
                    </td>
                    <!-- <td style="text-align: center;width:50px;">
                      <p-checkbox binary="mileStone.proposedCurrentOverride" [(ngModel)]="mileStone.proposedCurrentOverride" [disabled]="mileStone.proposedCurrentOverride || mileStone.duration == 0 || !mileStone.isCurrentEdit"
                      [value]="mileStone.proposedCurrentOverride" (click)="Getdates()"></p-checkbox>
                    </td> -->
                    <!-- <td style="text-align: center;width:50px;">{{mileStone.reason}}</td> -->
                    <td style="text-align: center;width:60px;">{{mileStone.duration}}</td>
                    <td style="text-align: center;width:60px;">{{mileStone.overlapDays}}</td>
                    <td style="padding-left: 15px;width:120px;">{{mileStone.predecessor}}</td>
                    <td style="text-align: center;width:60px;"><span *ngIf="mileStone.isWeekend === true">Yes</span><span *ngIf="mileStone.isWeekend === false">No</span></td>
                    <td style="padding-left: 15px;width:60px;">{{mileStone.taskStatus}}</td>
                </tr>
            </ng-template> 
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="15">There are no milestones for this product yet.</td>
                </tr>
            </ng-template>               
          </p-table>

            <!-- <p-table [value]="datesData" [expandedRowKeys]="expandedRows" dataKey="taskId" rowExpandMode="single"
              styleClass="p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 60px;"></th>
                  <th style="width:150px;">Type</th>
                  <th style="width:260px;">Name</th>
                  <th style="width:60px;">Seq</th>
                  <th style="width:100px;">Predecessor</th>
                  <th style="width:150px;">Assignee</th>
                  <th style="width:150px;">Status</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-dates let-expanded="expanded">
                <tr style="background-color:#eee;border-bottom: 3px solid #fff;">
                  <td class="width50">
                    <button type="button" pButton pRipple [pRowToggler]="dates"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                  </td>
                  <td style="padding-left: 30px;">{{dates.type}}</td>
                  <td style="padding-left: 30px;">{{dates.taskName}}</td>
                  <td style="text-align:center;">{{dates.taskSeq}}</td>
                  <td style="padding-left: 30px;">{{dates.predecessor}}</td>
                  <td style="padding-left: 30px;">{{dates.assigneeTo}}</td>
                  <td style="text-align:center">{{dates.taskStatus}} </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-dates>
                <tr style="border: 2px solid #e9ecef;">
                  <td colspan="3">
                    <div class="p-p-3">
                      <p-table [value]="dates.dates" dataKey="name">
                        <ng-template pTemplate="header">
                <tr>
                  <th style="width:260px;">Name</th>
                  <th style="width:260px;">Current</th>
                  <th style="width:260px;">Proposed</th>
                  <th style="width: 15rem">Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-date>
                <tr>
                  <td style="padding-left: 15px;">{{date.name}}</td>
                  <td style="text-align: center;">{{date.current}}</td>
                  <td style="text-align: center;">
                    <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="date.proposed" [disabled]="isReadOnly">
                    </p-calendar>
                  </td>
                  <td style="text-align: center;"><button
                      (click)="ViewSchedule(dates.taskId,date.proposed,dates.type,date.name)" pButton type="button"
                      label="View Schedule" class="p-button-text"></button></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="4">No Data found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          </td>
          </tr>
          </ng-template>
          </p-table> -->
         </div>
      </div>
    </div>

    <div class="card" *ngIf="PrjStatus!='New'">
      <h4 style="margin-top:5px;">Approval History</h4>
      <hr>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12" style="min-height:200px;overflow-x: auto;">
          <p-table #dt [value]="approvalsHistory" styleClass="p-datatable-striped" class="table-align" [rowHover]="true"
            [paginator]="false" [filterDelay]="0">
            <ng-template pTemplate="header" class="invoice-items">
              <tr>
                <th style="width: 200px">
                  Approved By
                </th>
                <th style="width: 150px">
                  Approved Date
                </th>
                <th style="width: 200px">
                  Department
                </th>
                <th style="width:200px">
                  Role
                </th>
                <th style="width: 100px">
                  Status
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-approvalsHistory>
              <tr [pSelectableRow]="approvalsHistory">
                <ng-container>

                  <td style="text-align: left;padding-left:25px;">
                    {{ approvalsHistory.approvedBy }} </td>

                  <td style="text-align: center;">
                    {{ approvalsHistory.approvedDate}}
                  </td>
                  <td style="text-align: left; padding-left:25px;">
                    {{ approvalsHistory.department }}
                  </td>
                  <td style="text-align: left;padding-left:25px;">
                    {{ approvalsHistory.role }}
                  </td>
                  <td style="text-align: center;">
                    {{ approvalsHistory.status }}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" style="text-align: left;">No data found...</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-field p-col-12 p-lg-7 p-md-12 p-sm-12"></div>
      <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
        <button *ngIf="isFromOther" pButton pRipple type="button" (click)="ChangeRequest('')" icon="pi pi-check"
        iconPos="left" label="Save" class="p-button-raised p-button-success"
        style="width: 100%;"></button>
        <button *ngIf="isFromApprovalNeeded" pButton pRipple type="button" (click)="approveRej(3)"
              icon="pi pi-check" iconPos="left" label="Approve" class="p-button-raised p-button-success"
              style="width: 100%;"></button>
      </div>
      <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12"> 
          <span class="p-input-icon-left" style="float:right;">
            <button *ngIf="isFromOther&&!isInprocess" pButton pRipple type="button" (click)="ChangeRequest('SP')"
              icon="pi pi-check" iconPos="left" label="Save and Send for Approval"
              class="p-button-raised p-button-success" style="width: 100%;"></button>            
            <button *ngIf="isFromApprovalNeeded" pButton pRipple type="button" (click)="approveRej(4)"
              icon="pi pi-times" iconPos="left" label="Reject" class="p-button-raised p-button-danger"
              style="width: 100%;"></button>            
          </span>      
        <small class="p-invalid" style="float: right">{{txtErrorMessage}}</small>
      </div>
    </div>
  </div>
</div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<p-dialog header="Comments" [(visible)]="commentsDisplay" modal="modal" showEffect="fade" [style]="{ width: '60%' }">
  <hr />
  <div class="p-grid">
    <div class="p-col-12" style="text-align: center">
      <div *ngIf="commentsList.length > 0" class="card no-gutter widget-overview-box widget-overview-box-1"
        style="min-height: 230px; overflow-y: scroll">
        <ul class="widget-image-list" *ngFor="let appr of commentsList">
          <li>
            <span class="listitem-value">
              <ul>
                <li style="text-align: left">
                  <div style="float: left">
                    <span style="
                              color: red;
                              font-size: 16px;
                              padding-right: 10px;
                            ">{{ appr.approvedBy }}:</span>
                    <span style="
                              color: #2196f3;
                              font-size: 14px;
                              padding-right: 10px;
                            ">{{
                      appr.approvedDate
                      | date: "MM/dd/yyyy hh:mm a"
                      }}:</span>{{ appr.comment }}
                  </div>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
      <div *ngIf="commentsList.length <= 0" class="card no-gutter widget-overview-box widget-overview-box-1"
        style="min-height: 230px; overflow-y: scroll">
        <ul class="widget-image-list">
          <li>
            <span class="listitem-value">
              <ul>
                <li style="text-align: left">
                  <div style="float: left">
                    <span style="font-size: 16px; padding-right: 10px">No Comments to show.</span>
                  </div>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</p-dialog>
<p-dialog header="Reject Change Request" [(visible)]="rejectdisplay" modal="modal" showEffect="fade"
  [style]="{ width: '60%' }">
  <hr />
  <div class="p-grid p-fluid">
    <div class="p-col-12" style="text-align: left">
      <label for="Rejectcomments">Are you sure you want to reject the change request ? please specify
        the reason.</label>
      <textarea [(ngModel)]="Rejectcomments" id="Rejectcomments" type="text" rows="4" [maxLength]="300"
        pInputTextarea></textarea>
    </div>
    <div class="p-col-12 p-md-8"></div>
    <div class="p-col-12 p-md-2"></div>
    <div class="p-col-12 p-md-2">
      <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
        <button pButton type="button" label="Continue" icon="pi pi-times" iconPos="left"
          (click)="ApproveRejectRequest(4)" class="p-button-raised p-button-danger"
          style="margin-top: -5px; margin-bottom: -5px"></button>
      </span>
    </div>
    <small class="p-invalid" style="float: right">{{txtErrorMessageAR}}</small>
  </div>
</p-dialog>

<p-dialog header="Approve Change Request" [(visible)]="approvedisplay" modal="modal" showEffect="fade"
  [style]="{ width: '60%' }">
  <hr />
  <div class="p-grid p-fluid">
    <div class="p-col-12" style="text-align: left">
      <label for="Comments">Comments:</label>
      <textarea [(ngModel)]="Comments" id="Comments" type="text" rows="4" [maxLength]="300" pInputTextarea></textarea>
    </div>

    <div class="p-col-12 p-md-8"></div>
    <div class="p-col-12 p-md-2"></div>
    <div class="p-col-12 p-md-2">
      <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
        <button pButton type="button" label="Continue" icon="pi pi-check" iconPos="left"
          (click)="ApproveRejectRequest(3)" class="p-button-raised p-button-success"
          style="margin-top: -5px; margin-bottom: -5px"></button>
      </span>
    </div>
    <small class="p-invalid" style="float: right">{{txtErrorMessageAR}}</small>
  </div>
</p-dialog>

<p-dialog header="Effected Tasks" [(visible)]="effectedTasksdisplay" modal="modal" showEffect="fade"
  [style]="{ width: '80%' }">
  <hr />
  <div class="card">
    <div>
      <div style="min-height:200px;margin-top: -14px;border: 2px solid #e9ecef;overflow-x: auto;">
        <p-table [value]="effectedTasksData" [expandedRowKeys]="expandedRows" dataKey="taskId" rowExpandMode="single"
          styleClass="p-datatable-striped">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:60px;"></th>
              <th style="width:260px;">Name</th>
              <th style="width:60px;">Seq</th>
              <th style="width:100px;">Predecessor</th>
              <th style="width:150px;">Assignee</th>
              <th style="width:150px;">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-effectedTask let-expanded="expanded">
            <tr style="background-color:#eee;border-bottom: 3px solid #fff;">
              <td class="width50">
                <button type="button" pButton pRipple [pRowToggler]="effectedTask"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              <td style="padding-left: 30px;">{{effectedTask.taskName}} </td>
              <td style="text-align:center">{{effectedTask.taskSeq}} </td>
              <td style="padding-left: 30px;">{{effectedTask.predecessor}} </td>
              <td style="padding-left: 30px;">{{effectedTask.assigneeTo}} </td>
              <td style="text-align:center">{{effectedTask.taskStatus }} </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">No tasks effected.</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-effectedTask>
            <tr style="border: 2px solid #e9ecef;">
              <td colspan="3">
                <div style="margin-left:50%;">
                  <p-table [value]="effectedTask.dates" dataKey="name">
                    <ng-template pTemplate="header">
            <tr>
              <th style="width:260px;">Name</th>
              <th style="width:260px;">Current</th>
              <th style="width:260px;">Proposed</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-date>
            <tr>
              <td style="padding-left: 15px;">{{date.name}}</td>
              <td style="text-align: center;">{{date.current| date: "MM/dd/yyyy"}}</td>
              <td style="text-align: center;">{{date.proposed| date: "MM/dd/yyyy"}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">No Data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </td>
      </tr>
      </ng-template>
      </p-table>
    </div>
  </div>
  </div>
</p-dialog>
<p-dialog header="CCB Board" [(visible)]="ShowCCB" modal="modal" showEffect="fade" [style]="{ width: '80%' }">
  <hr />
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12" style="min-height:200px;overflow-x: auto;">
      <span style=" font-weight: bold;color: #2196f3;">Product Line: {{productLine}}</span>
      <p-table #dt [value]="ccbList" styleClass="p-datatable-striped" class="table-align" [rowHover]="true"
        [paginator]="false" [filterDelay]="0">
        <ng-template pTemplate="header" class="invoice-items">
          <br>
          <tr>
            <th style="width: auto;">
              User Name
            </th>
            <th style="width: auto;">
              Department
            </th>
            <th style="width: auto;">
              Role
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ccb>
          <tr [pSelectableRow]="ccb">
            <ng-container>
              <td style="text-align: left; padding: 1rem;">
                {{ ccb.userName }}
              </td>
              <td style="text-align: left;padding: 1rem;">
                {{ ccb.departmentName }} </td>

              <td style="text-align: left;padding: 1rem;">
                {{ ccb.roleName}}
              </td>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" style="text-align: left;">No data found...</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>