import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-createccbcontrol',
  templateUrl: './createccbcontrol.component.html',
  styleUrls: ['./createccbcontrol.component.scss']
})
export class CreateccbcontrolComponent implements OnInit {

  constructor(private _facadeService: FacadeService,private router: Router,private route: ActivatedRoute) { }
  selectedProductLine:any;
  prodctLineData:any;
  departmentsData:any;
  selectedDepartment:any;
  prodctLine:any;
  departmentError:any;
  rolesError:any;
  selectedRole:any;
  rolesData:any;
  usersError:any;
  selectedUser:any;
  usersData:any;
  boardName:any;
  boardNameError:any;
  ccbboardline:any=[];
  showLoading:any;
  prodctLineError:any;
  responseError:any;
  urlId:any;
  isUpdate:boolean;
  HeaderName:any;
  userFeature:boolean=false;
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="CMS_SHOW_A_D_S_U_R");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;
      }
    }
    this.GetDepartments();
    this.urlId = this.route.snapshot.queryParamMap.get('id');
    if(this.urlId!=null && this.urlId!="" && this.urlId!=undefined){
      this.getCCBDetailsById();
      this.isUpdate=true;
      this.HeaderName="Update CCB Board";
    }
    else{
      this.GetProductLines('');
      this.HeaderName="Create CCB Board";
    }
  }
  resetCtrls(){
    this.selectedRole="";
    this.selectedUser="";
    this.rolesData=[];
    this.usersData=[];
    this.selectedDepartment="";
    this.ccbboardline=[];
    this.boardName="";
    this.boardNameError="";
    this.prodctLineError="";
    this.departmentError="";
    this.rolesError="";
    this.usersError="";
    this.selectedProductLine="";
    if(this.urlId!=null && this.urlId!=""&& this.urlId!=undefined){
      this.getCCBDetailsById();
      this.isUpdate=true;
      this.HeaderName="Update CCB Board";
    }
  }
  createCCBBoard(){
    this.boardNameError="";
    if(this.boardName==null || this.boardName=="" || this.boardName==undefined){
      this.boardNameError="Please enter board name";
    }
    else{
    this.showLoading = true;
    var cCBBoardLines={
      name:this.boardName,
      productLine:this.selectedProductLine.id,
      productName:this.selectedProductLine.name,
      approvalBoards:this.ccbboardline
    }
    this._facadeService.SaveCCBBoardLine(cCBBoardLines).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0){
          this.router.navigate(['/admin']);
        }
        else{
          this.responseError=data.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    }
  }

  updateCCBBoard(){
    this.boardNameError="";
    if(this.boardName==null || this.boardName=="" || this.boardName==undefined){
      this.boardNameError="Please enter board name";
    }
    else{
    this.showLoading = true;
    var cCBBoardLines={
      CCBGuid:this.urlId,
      name:this.boardName,
      productLine:this.selectedProductLine.id,
      productName:this.selectedProductLine.name,
      approvalBoards:this.ccbboardline
    }
    this._facadeService.UpdateCCBBoardLine(cCBBoardLines).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0){
          this.router.navigate(['/admin']);
        }
        else{
          this.responseError=data.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    }
  }

  saveCCB(){
    this.boardNameError="";
    this.prodctLineError="";
    this.departmentError="";
    this.rolesError="";
    this.usersError="";
    if(this.boardName==null || this.boardName=="" || this.boardName==undefined){
      this.boardNameError="Please enter board name";
    }
    else if(this.selectedProductLine==null || this.selectedProductLine=="" || this.selectedProductLine==undefined){
      this.prodctLineError="Please select product line";
    }
    else if(this.selectedDepartment==null || this.selectedDepartment=="" || this.selectedDepartment==undefined){
      this.departmentError="Please select department";
    }
    else if(this.selectedRole==null || this.selectedRole=="" || this.selectedRole==undefined){
      this.rolesError="Please select role";
    }
    else if(this.selectedUser==null || this.selectedUser=="" || this.selectedUser==undefined){
      this.usersError="Please select user";
    }
    else if(this.ccbboardline.find(m=>m.user==this.selectedUser.userGuid)){
      this.usersError="User already exist";
    }
    else{
      let BoardData={
          department:this.selectedDepartment.departmentGuid,
          role:this.selectedRole.roleGuid,
          user:this.selectedUser.userGuid,
          departmentName:this.selectedDepartment.departmentName,
          roleName:this.selectedRole.roleName,
          userName:this.selectedUser.fullName
      };
      this.ccbboardline.push(BoardData);
    }
  }

  deleteProductLine(selectedRow){
    let ccbPop=[];
      this.ccbboardline.forEach(element => {
        if(element.user!=selectedRow.user){
          ccbPop.push(element);
        }
      });
      this.ccbboardline=ccbPop;
  }

  GetProductLines(prodLine){
    this.showLoading = true;
    this._facadeService.GetProductLines().subscribe(
      (data) => {
        this.showLoading = false;
        this.prodctLineData = data.returnObject; 
        if(prodLine!="" && prodLine!=null && prodLine!=undefined){
          this.selectedProductLine = this.prodctLineData.find(m=>m.id==prodLine);
        }
        else{
          let prod = [];
          this.showLoading = true;
          this._facadeService.GetCCBDetails().subscribe(
            (data1) => {
              this.showLoading = false;
              if(data1.returnObject!=null){
                this.prodctLineData.forEach(element => {
                  var d= data1.returnObject.find(m=>m.productLine==element.id);
                  if(d==null){
                    prod.push(element);
                  }
                });
                  this.prodctLineData = prod;
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
          ); 
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  GetDepartments(){
    this.showLoading = true;
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.showLoading = false;
        this.departmentsData = data.returnObject; 
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  ChangeDepartment(){
    this.selectedRole="";
    this.selectedUser="";
    this.rolesData=[];
    this.usersData=[];
    this.getRolesByDepartmentGuids();
  }

  getRolesByDepartmentGuids(){
    this.departmentError="";
    if(this.selectedDepartment!=null && this.selectedDepartment!="" && this.selectedDepartment!=undefined){
      this.showLoading = true;
      let departmentArray=[];
      departmentArray.push(this.selectedDepartment.departmentGuid);
      this._facadeService
        .getRolesByDepartmentGuids(departmentArray)
        .subscribe((result: any) => {
          this.showLoading = false;
          this.rolesData = result.returnObject;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        });
    }
    else{
      this.departmentError="Please select atlest one department.";
      this.rolesData =[];
    }
  }

  ChangeRole(){
    this.selectedUser="";
    this.usersData=[];
    this.getUserList();
  }
  getUserList() {
    this.showLoading = true;
    this._facadeService
      .GetUsersByRoleGuid(this.selectedRole.roleGuid)
      .subscribe((result: any) => {
        this.showLoading = false;
        result.returnObject.forEach((value, key) => {
          value.fullName= value.firstName + " "+value.lastName;
        });
        this.usersData = result.returnObject;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
  }

  getCCBDetailsById(){
    this.showLoading = true;
    this.ccbboardline=[];
    this._facadeService.getCCBDetailsById(this.urlId).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null && data.returnObject.approvalBoards!=null &&data.returnObject.approvalBoards.length>0){
          this.ccbboardline=data.returnObject.approvalBoards;
          this.boardName =data.returnObject.name;
        this.GetProductLines(data.returnObject.productLine);
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
