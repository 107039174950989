import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem, SortEvent} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from '../../services/printcommon.service';
@Component({ 
  selector: 'app-changerequests',
  templateUrl: './changerequests.component.html',
  styleUrls: ['./changerequests.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangerequestsComponent implements OnInit {
  userId = sessionStorage.getItem('userGuid');
  status: SelectItem[];
  pageTitle: string = 'Create Document';
  cols: any[];
  selectedFromDate:any;
  selectedToDate:any;
  minDate:any;
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  changeRequestsData:any=[];
  selectedStatus:any[]=[];
  selectedStatusChecked:any[]=[];
  constructor(
    private _facadeService: FacadeService,public printService: PrintcommonService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  ngOnInit(): void {
    this.selectedToDate=new Date();
    let dte = new Date();
dte.setMonth(dte.getMonth() - 1);
this.selectedFromDate= new Date(dte.toString());
if(sessionStorage.getItem('CRStartDate') != null && sessionStorage.getItem('CRStartDate') != undefined)
    {
      if(sessionStorage.getItem('CRStartDate') != "")
      {
        this.selectedFromDate = this.datePipe.transform(sessionStorage.getItem('CRStartDate'), 'MM/dd/yyyy');
      }
    }
    if(sessionStorage.getItem('CREndDate') != null && sessionStorage.getItem('CREndDate') != undefined)
    {
      if(sessionStorage.getItem('CREndDate') != "")
      {
        this.selectedToDate = this.datePipe.transform(sessionStorage.getItem('CREndDate'), 'MM/dd/yyyy');
      }
    }

      this.cols = [
        { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'Search' },
        { field: 'changeGroupName', header: 'Change Type', dynamicPlaceHolder: 'Search' },      
        { field: 'changeItem', header: 'Change Item', dynamicPlaceHolder: 'Search' },
        { field: 'additionalInfo', header: 'Additional Info', dynamicPlaceHolder: 'Search' },
        { field: 'owner', header: 'Requested By', dynamicPlaceHolder: 'Search' },
        { field: 'createdAt', header: 'Requested Date', dynamicPlaceHolder: 'Search' },
          { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' }
      ];
  
      this.status = [ 
        {label: 'New', value: {id: 0, name: 'New', code: 1}},
        {label: 'In-Process', value: {id: 1, name: 'In-Process', code: 2}},
       {label: 'Approved', value: {id: 2, name: 'Approved', code: 3}},
        {label: 'Rejected', value: {id: 3, name: 'Rejected', code: 4}},
    ];
    var isFromDashboardTileStatus = this.route.snapshot.queryParamMap.get('status');
    if(isFromDashboardTileStatus!=null && isFromDashboardTileStatus!="" && isFromDashboardTileStatus!=undefined){
      var filteredStatus = this.status.find(m=>m.label==isFromDashboardTileStatus);
      this.selectedStatus.push(filteredStatus.value);
      this.selectedStatusChecked.push(Number(filteredStatus.value.code));
    }
    else{
      let ok = false;
      if(sessionStorage.getItem('CRStatus') != null && sessionStorage.getItem('CRStatus') != undefined)
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('CRStatus').split(',');
        projectstats.forEach((element) => {
          this.status.forEach(element1 => {
            if(element1.value.code == element)
            {
              this.selectedStatus.push(element1.value);
              this.selectedStatusChecked.push(Number(element));
            }
          });
        });
        ok = true;
      }
      if(!ok)
      { 
        this.selectedStatus.push(this.status[0].value,this.status[1].value,this.status[2].value,this.status[3].value)
        this.selectedStatusChecked.push(1,2,3,4);
        sessionStorage.CRStatus = this.selectedStatusChecked;
      }
    }
    this.getChangeRequestsData();
  }
  selectedStatusChange(selectedStatus){
    this.selectedStatusChecked=[];
    selectedStatus.value.forEach((value, key) => {
      this.selectedStatusChecked.push(value.code);
    });
  }
  getChangeRequestsData() {
    this.changeRequestsData=[];
    sessionStorage.CRStartDate = this.selectedFromDate;
    sessionStorage.CREndDate = this.selectedToDate;
    sessionStorage.CRStatus = this.selectedStatusChecked;
    var data={
      UserId:this.userId,
      statusIds:this.selectedStatusChecked,
      FromDate:this.datePipe.transform(this.selectedFromDate, "MM/dd/yyyy"),
      ToDate:this.datePipe.transform(this.selectedToDate, "MM/dd/yyyy")
    }
    this.showLoading = true;
    this._facadeService.getChangeRequestsData(data).subscribe(
      (data) => {
        if(data.returnObject!=null){
          data.returnObject.forEach((value, key) => {
            let createdAt = new Date(value.createdAt);
            value.createdAt = createdAt.toString();
            
          //  let convertactiondate = new Date(value.createdAt+'Z') 
          //   value.createdAt =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
             this.changeRequestsData.push(value);
             value.createdAt = this.datePipe.transform(value.createdAt, 'MM/dd/yyyy');
          });
        }
        console.log("Change Request data:"+JSON.stringify(data.returnObject));
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'My Change Requests','trSearch',true);
  }
  exportExcel() {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       this.delete_row(wb.Sheets.Sheet1, 1);
       ws['!cols'] = [{ wpx : 90 },{ wpx : 100 },{ wpx : 110 },{ wpx : 90 },{ wpx : 80 },{ wpx : 100 },,{ wpx : 80 }];
       /* save to file */
       XLSX.writeFile(wb, "My Change Requests.xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

ViewChangeRequest(rowdata){
  if(rowdata.changeGroupId==1){
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'partchangerequest?id='+rowdata.changeRequestGuid+'&source=CR';
  }
  if(rowdata.changeGroupId==2){
    location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'projectchangerequest?id='+rowdata.changeRequestGuid+'&source=CR';
  }
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    data1.createdAt = new Date(data1.createdAt);
   // data1.targetDate = new Date(data1.targetDate);
    data2.createdAt = new Date(data2.createdAt);
   // data2.targetDate = new Date(data2.targetDate);
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
          result = -1;
      else if (value1 != null && value2 == null)
          result = 1;
      else if (value1 == null && value2 == null)
          result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
      else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

          
      return (event.order * result);
  });
  event.data.forEach(e => {
        e.createdAt = this.datePipe.transform( e.createdAt, 'MM/dd/yyyy');
       // e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
});
}
}

