import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-partchangerequest',
  templateUrl: './partchangerequest.component.html',
  styleUrls: ['./partchangerequest.component.scss']
})
export class PartchangerequestComponent implements OnInit {
  userId = sessionStorage.getItem('userGuid');
  applications:any = JSON.parse(sessionStorage.getItem('applications'));
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  partDetails:any;
  cols:any;
  PartNo:any;
  PartName:any;
  ProdOwner:any;
  ProdStatus:any;
  ChangeType:any;
  selectedFile:boolean;
  partData:any;
  ActivePhase:any;
  selectedDate:any;
  minDate:any;
  Description:any;
  BusinesBenifits:any;
  Justification:any;
  showLoading:boolean;
  txtErrorMessage:string;
  DateError:string;
  ChangeRequestGuid:string;
  CustomFieldgroupId:any;
  ECRN:any;
  isFromApprovalNeeded:boolean;
  isFromOther:boolean;
  Comments:string;
  ReqId:Int16Array;
  productGuid:string;
  ItemDescription:any;
  datasourcelist: any = {};
  isReadOnly: boolean=false;
  tableError: any;
  selectedCustomField: any;
  dieCode:any;
  Rejectcomments:any;
  rejectdisplay:boolean;
  approvedisplay:boolean;
  approvalsData:any;
  approvalsHistory:any=[];
  changeRequestId:any;
  commentsList:any=[];
  commentsDisplay:boolean;
  txtErrorMessageAR:any;
  AttributesChanged : boolean;
  copyvalues:any = [];
  mfgPartNos:any=[];
  DescrptionError:any="";
  ReasonsError:any="";
  isInprocess:boolean;
  ShowCCB:boolean;
  ccbList:any=[];
  productLineName:any;
  constructor(
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  ngOnInit(): void {
    this.productLineName =this.route.snapshot.queryParamMap.get('productLine');
    var source = this.route.snapshot.queryParamMap.get('source');
    this.Comments = "Approved";
    if(source=="AN"){
      this.isFromApprovalNeeded=true;
    }
    else{
      this.isFromApprovalNeeded=false;
      this.isFromOther= true;
    }
   
    this.minDate=new Date();
    this.selectedDate = new Date();
    
    this.cols = [
      { field: 'title', header: 'Product Name', dynamicPlaceHolder: 'Search by product' }, 
      { field: 'code', header: 'MFG Part Number', dynamicPlaceHolder: 'Search by mfg part number' },
      { field: 'owner', header: 'Product Owner', dynamicPlaceHolder: 'Search by owner' }
    ];
    
    var partId = this.route.snapshot.queryParamMap.get('partId');
    if(partId!=null && partId!=""&& partId!=undefined){
      this.getPartDetailsById(parseInt(partId));
    }
    var changeReqId = this.route.snapshot.queryParamMap.get('id');
    if(changeReqId!=null && changeReqId!=""&& changeReqId!=undefined){
      this.getChangeRequestDetails(changeReqId);
    }
    this.GetMfgPartNos();
  }

  ChangeRequest(sendForApproval){
    this.txtErrorMessage ="";
    this.DateError="";
    this.copyvalues=[];
    this.DescrptionError="";
    this.ReasonsError="";
    if(this.selectedDate==""||this.selectedDate==undefined||this.selectedDate==null){
      this.txtErrorMessage="Please select Date";
    }
    else if(this.Description==""||this.Description==undefined||this.Description==null){
      this.txtErrorMessage="Please enter description";
    }
    else if(this.BusinesBenifits==""||this.BusinesBenifits==undefined||this.BusinesBenifits==null){
      this.txtErrorMessage="Please enter reasons";
    }
    else{
    let obj = {}; 
      this.CustomFieldgroupId.forEach((element) => {
        if (this.datasourcelist[element.customFieldID].value !== '' && this.datasourcelist[element.customFieldID].value !== null) {
         if(element.fieldName.toLowerCase().includes("mfg part #")){
          this.PartNo=this.datasourcelist[element.customFieldID].value;
            }
            obj = {
              customFieldID:element.customFieldID,
              DataSource: element.dataSource,
              FieldName: element.fieldName,
              ValueName:element.valueName,
              EntryType:element.entryType,
              Proposed:this.datasourcelist[element.customFieldID].value
            };
            this.copyvalues.push(obj);
        }
        else{
          obj = {
            customFieldID:element.customFieldID,
            DataSource: element.dataSource,
            FieldName: element.fieldName,
            ValueName:element.valueName,
            EntryType:element.entryType,
            Proposed:''
          };
          this.copyvalues.push(obj);
        }
      });
      if(this.copyvalues.length>0){
        var dieCodeRoot = this.copyvalues.find(m=>m.FieldName.toLowerCase().includes("die code (root)"));
        if(dieCodeRoot!=null && dieCodeRoot.Proposed!=null && dieCodeRoot.Proposed!="" 
        && dieCodeRoot.Proposed!=undefined && (dieCodeRoot.Proposed?.length<5 ||dieCodeRoot.Proposed?.length>6)){
          this.txtErrorMessage="Please enter minimum 5 and maximum 6 characters for Die Code (Root).";
          return;
        }
        // if(dieCodeRoot!=null && (dieCodeRoot.Proposed==null || dieCodeRoot.Proposed=="" 
        // || dieCodeRoot.Proposed==undefined)){
        //   this.txtErrorMessage="Please enter Die Code (Root).";
        //   return;
        // }
        var dieCoded = this.copyvalues.find(m=>m.FieldName.toLowerCase().includes("die code rev (d)"));
        if(dieCoded!=null && dieCoded.Proposed!=null && dieCoded.Proposed!="" 
        && dieCoded.Proposed!=undefined && dieCoded.Proposed?.length>1){
          this.txtErrorMessage="Please enter only 1 character for Die Code Rev (d).";
          return;
        }
        var dieCodee = this.copyvalues.find(m=>m.FieldName.toLowerCase().includes("die code rev (e)"));
        if(dieCodee!=null && dieCodee.Proposed!=null && dieCodee.Proposed!="" 
        && dieCodee.Proposed!=undefined&& dieCodee.Proposed?.length>1){
          this.txtErrorMessage="Please enter only 1 character for Die Code Rev (e).";
          return;
        }
        var dieCode ="";
        if(dieCodeRoot!=null && dieCodeRoot!="" && dieCodeRoot!=undefined){
          dieCode+=dieCodeRoot.Proposed+"-";
        }
        if(dieCoded!=null && dieCoded!="" && dieCoded!=undefined){
          dieCode+=dieCoded.Proposed;
        }
        if(dieCodee!=null && dieCodee!="" && dieCodee!=undefined){
          dieCode+=dieCodee.Proposed;
        }
        if(dieCode!="" && dieCode!=undefined&& dieCode!=null){
          this.dieCode = dieCode;
        }
        
        var mfgDetails = this.copyvalues.find(m=>m.FieldName.toLowerCase().includes("mfg part #"));
        if(mfgDetails!=null && mfgDetails.ValueName!=mfgDetails.Proposed){
          var isExist = this.mfgPartNos.some(x => x === mfgDetails.Proposed);
          if(isExist){
                 this.txtErrorMessage="Mfg Part no is already existed with another product.";
              return;
          }
        }
        if(mfgDetails!=null && mfgDetails.Proposed==""){
          this.txtErrorMessage="Please enter Mfg Part No.";
          return;
        }
      }
      var changeReqId = this.route.snapshot.queryParamMap.get('id');
      if(sendForApproval=="SP"){
        this.ChangedCustomAttributes(this.copyvalues);
        if(!this.AttributesChanged){
             this.txtErrorMessage="Please change atleast one custom attribute for change request.";
             return;
        }
        else{
          if(changeReqId!=null && changeReqId!=""&& changeReqId!=undefined){
            this.UpdateChangeRequest(sendForApproval);
          }
          else{
            this.CreateChangeRequest(sendForApproval);
          }
        }
      }
      else{
        if(changeReqId!=null && changeReqId!=""&& changeReqId!=undefined){
          this.UpdateChangeRequest(sendForApproval);
        }
        else{
          this.CreateChangeRequest(sendForApproval);
        }
      }
    }
  }
  getChangeRequestDetails(changeId){
    this.showLoading = true;
    var DCCurrentRoot= "",DCCurrentD= "",DCCurrentE= "";
    var DCPropRoot= "",DCPropD= "",DCPropE= "";
    this._facadeService.GetChangeRequestDetailsById(changeId).subscribe(
      (data) => {
        this.showLoading = false;
        this.partDetails = data.returnObject;
        console.log("Part Response data:"+JSON.stringify(this.partDetails));
        if(this.partDetails!=null){
          this.changeRequestId= this.partDetails.id;
          this.ECRN=this.partDetails.ecrn;
          this.ProdOwner=this.partDetails.itemOwner;
          this.ProdStatus=this.partDetails.statusName;
          this.ChangeType=this.partDetails.changeGroupName;
          this.ActivePhase=this.partDetails.activePhase;
          this.Description=this.partDetails.description;
          this.Justification=this.partDetails.justification;
          this.BusinesBenifits=this.partDetails.businessBenefits;
          this.selectedDate= new Date(this.partDetails.date);
          this.PartName=this.partDetails.name;
          this.PartNo=this.partDetails.changeItem;
          this.ChangeRequestGuid=this.partDetails.changeRequestGuid;
          this.ReqId=this.partDetails.id;
          this.ItemDescription = this.partDetails.itemDescription;
          this.productLineName = this.partDetails.productLine;
          if(this.ProdStatus!="New"){
            this.isReadOnly=true;
            this.isFromOther=false;
            this.GetApprovalsHistory();
          }
          if(this.partDetails.statusId==3 || this.partDetails.statusId==4){
            this.isFromOther=false;
            this.isFromApprovalNeeded=false;
          }
          var source = this.route.snapshot.queryParamMap.get('source');
          if(source=="AN"){
            var ccb= JSON.parse(this.route.snapshot.queryParamMap.get('CCBApproved'));
            if(ccb){
              this.isFromOther=false;
              this.isFromApprovalNeeded=false;
            }
          }
          this.CustomFieldgroupId = this.partDetails.changeRequestAttributes;
        console.log("custom attributes Response data:"+JSON.stringify(this.CustomFieldgroupId));
        if (this.CustomFieldgroupId.length > 0) {
          this.CustomFieldgroupId.forEach((element) => {

            let colname = element.customFieldID.toString();
            this.datasourcelist[colname] = {
              list: [],
              value: "",
            };
            //element.view = true;
            // if(element.fieldName.toString().toLowerCase().includes("die code (full)")){
            //   element.view = false;
            //   this.dieCode=element.valueName;
            // }
            if(element.fieldName=="MFG Part #"){
              this.PartNo=element.value;
            }
            element.view = true;
            if(element.fieldName.toString().toLowerCase().includes("die code (full)")){
              element.view = false;
              this.dieCode=element.value;
            }
            element.length = 0;

            if(element.entryType == "TextBox")
            {
                if(element.fieldDataType.toLowerCase().includes("varchar"))
                {
                  var e;
                  e = element.fieldDataType.toLowerCase().replace("varchar(", "");
                  e = e.toLowerCase().replace(")", ""); 
                  element.length = Number(e);
                }
                else if(element.fieldDataType.toLowerCase().includes("decimal"))
                {
                  var e;
                  e = element.fieldDataType.toLowerCase().replace("decimal(", "");
                  e = e.toLowerCase().replace(")", ""); 
                  e = e.toLowerCase().replace(" ", ""); 
                  var splitted = e.split(",");
                  if(Number(splitted[1]) > 0)
                    element.length = Number(splitted[0]) + Number(splitted[1]) + 1;
                  else
                    element.length = Number(splitted[0]) + Number(splitted[1]);
                }
            }
            if(!element.fieldName.toString().toLowerCase().includes("die code (full)"))
            {
              if(this.ProdStatus=="New"){
                this.DataSourceAsQuery(element.dataSource, colname,element.proposed);
              }
              else{
                this.DataSourceAsQueryInProcess(element.entryType,element.dataSource, colname,element.proposed);
              }
            }
            if(element.fieldName.toString().toLowerCase().includes("die code (root)")){
              DCCurrentRoot=element.valueName;
              DCPropRoot = element.proposed;
              if(DCCurrentRoot == null) DCCurrentRoot = "";
              if(DCPropRoot == null) DCPropRoot = "";
            }
            if(element.fieldName.toString().toLowerCase().includes("die code rev (d)")){
              DCCurrentD=element.valueName;
              DCPropD = element.proposed;
              if(DCCurrentD == null) DCCurrentD = "";
              if(DCPropD == null) DCPropD = "";
            }
             if(element.fieldName.toString().toLowerCase().includes("die code rev (e)")){
              DCCurrentE=element.valueName;
              DCPropE = element.proposed;
              if(DCCurrentE == null) DCCurrentE = "";
              if(DCPropE == null) DCPropE = "";
            }
          });
          this.CustomFieldgroupId.forEach((element) => {
            if(element.fieldName.toString().toLowerCase().includes("die code (full)")){
              element.valueName = DCCurrentRoot + "-" + DCCurrentD + DCCurrentE;
              let colname = element.customFieldID.toString();
              this.datasourcelist[colname] = {
                list: [],
                value:  DCPropRoot + "-" + DCPropD + DCPropE,
              };
            }
          });
        }
         if(this.partDetails.isInprocess){
          this.isInprocess=true;
         }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  approveRej(statusId){
    this.txtErrorMessageAR="";
    if(statusId==4){
this.rejectdisplay=true;
    }
    else{
this.approvedisplay=true;
    }
  }
  ApproveRejectRequest(statusId){
    if(statusId==4 && (this.Rejectcomments=="" ||this.Rejectcomments==undefined||this.Rejectcomments==null)){
     this.txtErrorMessageAR="Please enter comments";
    }
    else{
      var model={
        StatusId :statusId,
        ChangeRequestId :this.ReqId,
        ApprovedBy : this.userId,
        Comment:statusId==3?this.Comments:this.Rejectcomments,
        LocalTime:new Date()
      }
      console.log("Request:"+JSON.stringify(model))
          this.showLoading = true;
          this._facadeService.ApproveRejectChangeRequest(model).subscribe(
            (data) => {
              console.log("Response:"+JSON.stringify(data))
              if(data!=null&& data!=undefined&&data!=""&&data.returnCode==0){
                if(statusId==3){
                  location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'approvalsneeded?status=Approved';
                }
                else{
                  location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'approvalsneeded?status=Rejected';
                }
              }
              else{
                this.txtErrorMessageAR="Unable to save data. Please try again.";
              }
              this.showLoading = false;
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            } 
          );
    }
  }

  CreateChangeRequest(sendForApproval){
      var model={
        changeGroupId: 1,
        changeItem: this.PartNo,
        name: this.PartName,
        description: this.Description,
        date: this.selectedDate,
        businessBenefits: this.BusinesBenifits,
        justification: this.Justification,
        createdBy: this.userId,
        saveAndSendForApproval: sendForApproval=="SP"?true:false,
        managerGuid:sessionStorage.getItem('ManagerGuid'),
        activePhase:this.ActivePhase,
        itemGuid:this.productGuid,
        itemDescription:this.ItemDescription,
        AdditionalInfo:this.dieCode,
        itemOwner:this.ProdOwner,
        changeRequestAttributes:this.copyvalues,
        productLine:this.route.snapshot.queryParamMap.get('productLine')
      }
    console.log("Create Request:"+JSON.stringify(model))
    this.showLoading = true;
    this._facadeService.CreateChangeRequest(model).subscribe(
      (data) => {
        console.log("Create Request:"+JSON.stringify(data))
        if(data!=null&& data!=undefined&&data!=""&&data.returnCode==0){
          if(sendForApproval=="SP"){
            location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'changerequests?status=In-Process';
          }
          else{
            this.router.navigate(["/changerequests"]);
          }
        }
        else{
          this.txtErrorMessage="Unable to save data. Please try again.";
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  UpdateChangeRequest(sendForApproval){
    var model={
      changeItem: this.PartNo,
      name: this.PartName,
      description: this.Description,
      date: this.selectedDate,
      businessBenefits: this.BusinesBenifits,
      justification: this.Justification,
      modifiedBy: this.userId,
      saveAndSendForApproval: sendForApproval=="SP"?true:false,
      ManagerGuid:sessionStorage.getItem('ManagerGuid'),
      ChangeRequestGuid:this.ChangeRequestGuid,
      itemDescription:this.ItemDescription,
      changeRequestAttributes:this.copyvalues,
      AdditionalInfo:this.dieCode,
    }
    console.log("Update Request:"+JSON.stringify(model))
    this.showLoading = true;
    this._facadeService.UpdateChangeRequest(model).subscribe(
      (data) => {
        console.log("Update Response:"+JSON.stringify(data))
        if(data!=null&& data!=undefined&&data!=""&&data.returnCode==0){
          if(sendForApproval=="SP"){
            location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"]+'changerequests?status=In-Process';
          }
          else{
            this.router.navigate(["/changerequests"]);
          }
        }
        else{
          this.txtErrorMessage="Unable to save data. Please try again.";
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetMfgPartNos(){
    this.showLoading = true;
    this._facadeService.GetAllMfgParts().subscribe(
      (data) => {
         this.mfgPartNos = data.returnObject;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  GetApprovalsHistory(){
    this.showLoading = true;
    this._facadeService.GetApprovalsHistory(this.userId,this.changeRequestId).subscribe(
      (data) => {
        if(data.returnObject!=null && data.returnObject.length>0){
          data.returnObject.forEach((value, key) => {
            if(value.approvedDate!=null){
              let convertactiondate = new Date(value.approvedDate+'Z') 
              value.approvedDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
               this.approvalsHistory.push(value);
              if (value.comment != null && value.comment != "") {
                this.commentsList.push(value);
              }
            }
            else{
              this.approvalsHistory.push(value);
              if (value.comment != null && value.comment != "") {
                this.commentsList.push(value);
              }
            }
          });
        }
       
        console.log("History:"+JSON.stringify(data));
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  BackClick(){
    var source = this.route.snapshot.queryParamMap.get('source');
    if(source=="CR"){
      this.router.navigate(["/changerequests"]);
    }
    else if(source=="AN"){
      this.router.navigate(["/approvalsneeded"]);
    }
    else if(source=="D"){
      this.router.navigate(["/dashboard"]);
    }
    else if(source=="R"){
      this.router.navigate(["/reports"]);
    }
    else {
      this.router.navigate(["/createnewrequestProdcut",{ S: 'Product' }]);
    }
  }

  keyPressNumbers(event,type) {
    if(type.toLowerCase().includes("decimal"))
    {
      var e;
      e = type.toLowerCase().replace("decimal(", "");
      e = e.toLowerCase().replace(")", ""); 
      var splitted = e.split(",");
      var resut;
      if(event.target.value.includes("."))
        resut=  new RegExp("^[0-9]{0," + splitted[0] +"}\.?[0-9]{0," + splitted[1] + "}$").test(event.target.value);
      else
        resut=  new RegExp("^[0-9]{0," + splitted[0] +"}$").test(event.target.value);
      if (!resut)
        event.target.value = "";
    }
  }
  
  getPartDetailsById(partId){
    this.showLoading = true;
    this._facadeService.getPartDetailsById(partId,this.userId).subscribe(
      (data) => {
        this.partDetails = data.returnObject;
        console.log("Part Response data:"+JSON.stringify(this.partDetails));
        if(this.partDetails!=null){
          //this.PartNo=this.partDetails.mfgPart;
          this.PartName=this.partDetails.productName;
          this.ProdOwner=this.partDetails.productOwner;
          this.ProdStatus="New";
          this.ChangeType="Product";
          this.ActivePhase=this.partDetails.currentPhase;
          this.productGuid = this.partDetails.productGuid;
          this.ItemDescription = this.partDetails.description;
          this.dieCode =this.partDetails.dieCode;
          this.getPartCustomAttributes();
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  DataSourceAsQuery(query, colname, Value) {
      this._facadeService
      .DataSourceAsQuery(query)
      .subscribe((data) => {
        this.datasourcelist[colname] = {
          list: data.returnObject,
          value: Value,
        };
      });
  }

  DataSourceAsQueryInProcess(entryType,query, colname, Value) {
    this.showLoading=true;
    this._facadeService
    .DataSourceAsQuery(query)
    .subscribe((data) => {
      this.showLoading = false;
      if(entryType=="DropDown"){
        var datavalue = data.returnObject.find(m=>m.id==Value);
        if(datavalue!=null){
          Value = datavalue.name;
        }
      }
      this.datasourcelist[colname] = {
        list: data.returnObject,
        value: Value,
      };
    });
}

  getPartCustomAttributes(){
    this.showLoading = true;
    var applicationDetails = this.applications.find(m=>m.name=="Product Management");
    this._facadeService.getPartCustomAttributes(this.productGuid,applicationDetails.id).subscribe(
      (data) => {
        this.CustomFieldgroupId = data.returnObject;
        console.log("custom attributes Response data:"+JSON.stringify(this.CustomFieldgroupId));
        if (this.CustomFieldgroupId.length > 0) {
          this.CustomFieldgroupId.forEach((element) => {
            let colname = element.customFieldID.toString();
            this.datasourcelist[colname] = {
              list: [],
              value: "",
            };
            if(element.fieldName=="MFG Part #"){
              this.PartNo=element.value;
            }
            element.view = true;
            if(element.fieldName.toString().toLowerCase().includes("die code (full)")){
              element.view = false;
              this.dieCode=element.value;
            }
            element.length = 0;
            if(element.entryType == "TextBox")
            {
                if(element.fieldDataType.toLowerCase().includes("varchar"))
                {
                  var e;
                  e = element.fieldDataType.toLowerCase().replace("varchar(", "");
                  e = e.toLowerCase().replace(")", ""); 
                  element.length = Number(e);
                  element.fieldName = element.fieldName + " ("+ e + " char)";
                }
                else if(element.fieldDataType.toLowerCase().includes("decimal"))
                {
                  var e;
                  e = element.fieldDataType.toLowerCase().replace("decimal(", "");
                  e = e.toLowerCase().replace(")", ""); 
                  e = e.toLowerCase().replace(" ", ""); 
                  var splitted = e.split(",");
                  if(Number(splitted[1]) > 0)
                    element.length = Number(splitted[0]) + Number(splitted[1]) + 1;
                  else
                    element.length = Number(splitted[0]) + Number(splitted[1]);
                  element.fieldName = element.fieldName + " (";
                  for (let i = 0; i < splitted[0]; i++) {
                    element.fieldName = element.fieldName + "n";
                  }
                  if(Number(splitted[1]) > 0)
                  {
                    element.fieldName = element.fieldName + ".";
                    for (let i = 0; i < splitted[1]; i++) {
                      element.fieldName = element.fieldName + "n";
                    }
                  }
                  element.fieldName = element.fieldName + ")";
                }
            }
          this.DataSourceAsQuery(element.dataSource, colname,element.value);
          });
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  ChangedCustomAttributes(obj){
    this.AttributesChanged = false;
    if (obj.length > 0) {
      obj.forEach((element) => {
        var drpValue ="";
      if(element.EntryType=="DropDown"){
       var queryList  = this.datasourcelist[element.customFieldID].list;
       var datavalue = queryList.find(m=>m.id==element.Proposed);
              if(datavalue!=null){
              drpValue = datavalue.name;
            }
          }
       if(element.ValueName!=drpValue && element.EntryType=="DropDown"){
             this.AttributesChanged = true;
       }
       else if(element.ValueName!=element.Proposed && element.EntryType=="TextBox"){
        this.AttributesChanged = true;
      }
      });
    }
  }
  ViewCCB(){
    this.showLoading = true;
    this.ShowCCB=true;
    this._facadeService.ViewCCB(this.productLineName).subscribe(
      (data) => {
        console.log("CCB data:"+JSON.stringify(data.returnObject));
        if(data.returnObject!=null && data.returnObject.approvalBoards!=null && data.returnObject.approvalBoards.length>0){
          this.ccbList=data.returnObject.approvalBoards;
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
}

