<div class="layout-dashboard">
  <div class="p-grid">

    <div class="p-col-12 p-md-12 p-xl-4">
      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
        <span class="overview-icon">
          <i class="pi pi-pencil"></i>
        </span>
        <span class="overview-title">My Change Requests</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" (click)="TileClick('New')" style="cursor: pointer">
            <div class="overview-number">
              {{dashBoardCountsNew }}
            </div>
            <div class="overview-subtext">New</div>
          </a>
          <a class="p-col-6" (click)="TileClick('In-Process')" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCountsInprocess }}
            </div>
            <div class="overview-subtext">In-Process</div>
          </a>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-xl-4">
      <div class="card no-gutter widget-overview-box widget-overview-box-2" style="min-height:150px">
        <span class="overview-icon">
          <i class="pi pi-check-square"></i>
        </span>
        <span class="overview-title">My Approvals</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" (click)="TileClickApproval('Waiting For Approval')" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCountsPending }}
            </div>
            <div class="overview-subtext">Pending</div>
          </a>
          <a class="p-col-6" (click)="TileClickApproval('Approved')" style="cursor: pointer">
            <div class="overview-number">
              {{dashBoardCountsApproved }}
            </div>
            <div class="overview-subtext">Approved</div>
          </a>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-xl-4">
      <div class="card no-gutter widget-overview-box widget-overview-box-3" style="height: 150px;">
        <span class="overview-icon">
          <i class="pi pi-check-square"></i>
        </span>
        <span class="overview-title">Change Request Breakup</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" (click)="ChangeBreakUp()" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCountsProducts }}
            </div>
            <div class="overview-subtext">Products</div>
          </a>
          <a class="p-col-6" (click)="ChangeBreakUp()" style="cursor: pointer">
            <div class="overview-number">
              {{dashBoardCountsProjects }}
            </div>
            <div class="overview-subtext">Projects</div>
          </a>
          <!-- <a class="p-col-4" (click)="ChangeBreakUp()" style="cursor: pointer">
            <div class="overview-number">
              {{dashBoardCountsDocuments }}
            </div>
            <div class="overview-subtext">Documents</div>
          </a> -->
        </div>
      </div>
    </div>

    <!-- <div class="p-col-12 p-md-6 p-xl-4">
      <div class="card no-gutter widget-overview-box widget-overview-box-3"style="height:150px;">
        <span class="overview-icon">
          <i class="pi pi-copy"></i>
        </span>
        <span class="overview-title">Reports</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" style="cursor: pointer">
            <div class="overview-number">{{ dashBoardCountsReports }}</div>
            <div class="overview-subtext">Total</div>
          </a>
        </div>
      </div>
    </div> -->
    <div class="p-col-12 p-lg-8 p-md-12 p-sm-12">
      <div class="card" style="height:400px;overflow-x: auto;">
        <h4>Change Requests by Status</h4>
        <hr>
        <div class="p-grid p-formgrid">
          <div class="p-field p-col-12 p-md-8">
            <div *ngIf="isChartDataAvailable;else Chartemptymessage;" class="revenue-chart-container" >
              <p-chart type="pie" id="revenue-chart" [data]="documentChart" [options]="options" ></p-chart>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-4"> </div>
          <ng-template #Chartemptymessage>
            <div style="text-align: center;margin-left: 15px;">No status found.</div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
      <div class="card" style="height:400px; overflow-y: scroll;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4 style="margin-top:5px;">Timeline</h4>
          <p-dropdown [options]="TimelineOptions" (onChange)="GetTimeline($event.value)"
            [(ngModel)]="selectedTimelineOption" optionLabel="name" styleClass="dashbard-demo-dropdown"
            [ngStyle]="{'min-width': '8rem'}"></p-dropdown>
        </div>
        <hr>
        <div class="widget-timeline" *ngIf='isTimelineDataAvailable;else TimeLineemptymessage'>
          <div *ngFor="let item of Timeline;">
            <div class="timeline-event">
              <span class="timeline-event-icon" style="background-color: #64B5F6;">
                <i class="pi {{item.icon}}"></i>
              </span>
              <div class="timeline-event-title">{{item.title}}</div>
              <div class="timeline-event-detail" (click)="changeRequest(item)" [innerHTML]="item.subTitleMod"></div>

            </div>
          </div>
        </div>
        <ng-template #TimeLineemptymessage>
          <div style="text-align: center;">No timeline found.</div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">

      <h5 style="color:#495057; padding:15px 0 0 10px;width: 60%;">Recent Change Requests</h5>
      <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">

        <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
          tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
          style="float:right;height:25px; width:25px;"></button>
        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
          tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
          style="float:right; height:25px; width:25px;"></button>

      </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">
      <!-- <p-table #dt [value]="changeRequestData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="380px"
        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id" -->
        <p-table  (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="changeRequestData" id="excel-table" [columns]="cols" class="table-align" [scrollable]="true" scrollHeight="250px"
              [filterDelay]="0" selectionMode="multiple" dataKey="id" styleClass="p-datatable-striped"
        [globalFilterFields]="['ecrn','changeGroupName','changeItem','additionalInfo','owner','createdAt','statusName']">
        <ng-template pTemplate="header" let-columns class="invoice-items">
          <tr>
            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'cItem':i==2,'status': i == 6,'ecrn':i==0,'reqBy':i==4,'reqDate':i==5,'cType':i==1,
            'additionalInfo':i==3}">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th style="width:80px;">Actions</th>
          </tr>
          <tr id="trSearch">
            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
              <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="dt.filter($event.target.value, col.field, 'contains')"
                style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
            </th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

              <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 123px;">
                {{ rowData[col.field] }}
              </td>
              <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 140px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 270px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 250px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 140px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 5" style="text-align: center;padding-left:15px;width: 140px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 6" style="text-align: left;padding-left:15px;width: 120px;">
                {{rowData[col.field]}}
              </td>
            </ng-container>
            <td style="text-align: center;width: 80px;">
              <button pButton type="button" (click)="changeRequest(rowData)"
                icon="pi pi-eye" pTooltip="View" tooltipPosition="right" class="p-button-raised p-button-primary"
                style="width:25px; height:25px;text-align:center;"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" style="text-align: left;">No data found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>