<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-fluid p-formgrid p-grid">
                <div class=" p-col-12 p-md-2">
                    <a routerLink="/admin"> <button pButton pRipple type="button" icon="pi pi-chevron-left"
                            iconPos="left" label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                            style="width:auto;"></button></a>
                </div>
                <div class=" p-col-12 p-md-8" style="text-align:center;">
                    <h4 style="margin-top:5px;">{{HeaderName}}</h4>
                </div>
                <div class=" p-col-12 p-md-2">
                </div>
            </div>
            <hr />

            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2"></div>
                <div class="p-field p-col-12 p-md-8">
                    <div class="p-fluid p-formgrid p-grid">

                        <div class="p-field p-col-12 p-md-6">
                            <label for="boardName">Board Name <span style="color: red">*</span></label>
                            <input id="boardName" name="boardName" type="text" [(ngModel)]="boardName" pInputText />
                            <small class="p-invalid">{{ boardNameError }}</small>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label for="prodctLine">Product Line<span style="color: red">*</span></label>
                            <p-dropdown inputId="prodctLine" [options]="prodctLineData"
                                [(ngModel)]="selectedProductLine" placeholder="Select" optionLabel="name"
                                [disabled]="isUpdate">
                            </p-dropdown>
                            <small class="p-invalid">{{ prodctLineError }}</small>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label for="Department">Department<span style="color: red">*</span></label>
                            <p-dropdown inputId="Department" [options]="departmentsData"
                                [(ngModel)]="selectedDepartment" placeholder="Select" (onChange)="ChangeDepartment()"
                                optionLabel="departmentName">
                            </p-dropdown>
                            <small class="p-invalid">{{ departmentError }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="Role">Role<span style="color: red">*</span></label>
                            <p-dropdown inputId="role" [options]="rolesData" [(ngModel)]="selectedRole"
                                placeholder="Select" (onChange)="ChangeRole()" optionLabel="roleName">
                            </p-dropdown>
                            <small class="p-invalid">{{ rolesError }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="User">User<span style="color: red">*</span></label>
                            <p-dropdown inputId="User" [options]="usersData" [(ngModel)]="selectedUser"
                                placeholder="Select" optionLabel="fullName">
                            </p-dropdown>
                            <small class="p-invalid">{{ usersError }}</small>
                            <br>
                        </div>

                        <div class="p-field p-col-12 p-md-3" style="margin-top: 5px; margin-left: 5px;">
                            <label></label>
                            <button pButton pRipple type="button" *ngIf="userFeature" (click)="saveCCB()"
                                icon="pi pi-plus" label="Add" class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                style="float:right;"></button>
                            <br>
                        </div>
                        <div class="p-field p-col-12 p-md-3"></div>
                        <div class="p-field p-col-12 p-md-3"></div>

                        <div class="p-col-12" style="overflow-x: auto;">
                            <br>
                            <p-table #dt [value]="ccbboardline" sortMode="multiple" styleClass="p-datatable-striped"
                                [filterDelay]="0">
                                <ng-template pTemplate="header" class="invoice-items">
                                    <tr>
                                        <th style="width: 200px;">
                                            Department
                                        </th>
                                        <th style="width: 200px;">
                                            Role
                                        </th>
                                        <th style="width: 200px;">
                                            User
                                        </th>
                                        <th style="width: 200px;">
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-ccbboardline>
                                    <tr [pSelectableRow]="ccbboardline">
                                        <td style="text-align:left; padding-left:15px; ">{{ ccbboardline.departmentName
                                            }}</td>
                                        <td style="text-align:left; padding-left:15px; ">{{ ccbboardline.roleName }}
                                        </td>
                                        <td style="text-align:left; padding-left:15px; ">{{ ccbboardline.userName }}
                                        </td>
                                        <td style="text-align:center; padding-left:15px; ">
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                *ngIf="userFeature"
                                                class="p-button-raised p-button-danger p-mr-2 p-mb-2"
                                                style="width: 25px; height:25px;"
                                                (click)="deleteProductLine(ccbboardline)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4">No data found.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-2"></div>
            </div>
            <div class="p-col-12"></div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2"></div>
                <div class="p-field p-col-12 p-md-4"></div>
                <div class="p-field p-col-12 p-md-2"><br>
                    <button *ngIf="(!isUpdate && ccbboardline.length>0) && userFeature" pButton pRipple
                        type="button" (click)="createCCBBoard()" icon="pi pi-check" label="Submit"
                        class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float:right;"></button>
                    <button *ngIf="(isUpdate&& ccbboardline.length>0) && userFeature" pButton pRipple
                        type="button" (click)="updateCCBBoard()" icon="pi pi-check" label="Update"
                        class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float:right;"></button>
                    <span style="text-align:center; font-size:18px; color:red;">{{ responseError }}</span>
                </div>
                <div class="p-field p-col-12 p-md-2"><br>
                    <button *ngIf="userFeature" pButton pRipple type="button" icon="pi pi-refresh"
                        (click)="resetCtrls()" label="Reset" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; margin-left:10px ; "></button>
                </div>
                <div class="p-field p-col-12 p-md-2"></div>
            </div>

        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>