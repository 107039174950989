import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helpguide',
  templateUrl: './helpguide.component.html',
  styleUrls: ['./helpguide.component.scss']
})
export class HelpguideComponent implements OnInit {
  showexpand: boolean = false;
  showplus: boolean = true;
  showminus: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  showexpand1() {
    if (
      this.showexpand
    ) {
      this.showexpand = false;
      this.showplus=true;
      this.showminus=false;
    }
    else {
      this.showexpand = true;
      this.showplus=false;
      this.showminus=true;
    }
  }

}
