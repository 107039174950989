<div class="layout-footer" style="margin-top: -2rem !important">
  <div class="footer-logo-container">
    <img
      id="footer-logo"
      src="assets/gallery/logoicon.png"
      alt="FactoryPRO-Logoicon"
    />
    <span class="app-name">FactoryPRO</span>
  </div>
  <a href="http://www.solsemusa.com/" target="_blank"><span class="copyright">&#169; SOLSEMUSA - {{year}}</span></a>
</div>
