import { Component, Inject, OnInit } from "@angular/core";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { FacadeService } from "../../facade/facade.service";
import { Router } from "@angular/router";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { API_ENDPOINTS } from "src/app/app.component";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private _facadeService: FacadeService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {}
 
  loginModel: any;
  userName: any;
  password: any;
  loginMessage: any;
  loginFlag: boolean = false;
  showLoading:boolean;
  ngOnInit(): void {
    sessionStorage.userId = "0";
    document.querySelector('input').focus();
    sessionStorage.clear();
  }
  onEnter(e) {
    var code = e.keyCode || e.which;
    if (code === 13) {
        e.preventDefault();
        this.login();
    };
  }

  login() {
    // let upperCaseCharacters = /[A-Z]+/g;
    // let lowerCaseCharacters = /[a-z]+/g;
    // let numberCharacters = /[0-9]+/g;
    let strongPassword: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    if (this.userName == undefined || this.userName == "") {
      this.loginFlag = true;
      this.loginMessage = "Please enter valid username.";
    } else if (this.password == undefined || this.password == "") {
      this.loginFlag = true;
      this.loginMessage = "Please enter  password.";
    } else if (strongPassword.test(this.password) == false) {
      this.loginFlag = true;

      this.loginMessage = "Please enter valid password.";
    }
    if (
      this.userName != undefined &&
      this.userName != "" &&
      this.password != undefined &&
      this.password != "" &&
      strongPassword.test(this.password) == true
    ) {
      this.loginModel = {
        Email: this.userName,
        Password: this.password,
      };
      this.showLoading = true;
      this._facadeService.login(this.loginModel).subscribe(
        (data) => {
          this.showLoading = false;
          if (data.returnCode == 0) {
            sessionStorage.userId = data.returnObject.userId;
            sessionStorage.userGuid = data.returnObject.userGuid;
            sessionStorage.firstName = data.returnObject.firstName;
            console.log(data.returnMessage);
            this.router.navigate(["/dashboard"]);
            console.log(data);
          } else {
            this.loginFlag = true;
            this.loginMessage = data.returnMessage;

            console.log(data.returnMessage);
          }
        },

        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
}
