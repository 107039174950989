<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-formgrid">
                <div class="p-field p-col-12 p-lg-4 p-md-12">
                    <div class="p-grid p-formgrid" *ngIf="ShowCustom">
                        <div class="p-field p-col-12 p-lg-6 p-md-12 p-sm-12">
                            <span>
                                <label style="margin-top: 10px;">Select Date:</label>
                                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedFromDate"
                                    placeholder="From"></p-calendar>
                            </span>
                        </div>
                        <div class="p-field p-col-12 p-lg-6 p-md-12 p-sm-12">
                            <span>
                                <label style="margin-top: 10px;">Select Date:</label>
                                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedToDate"
                                    placeholder="To"></p-calendar>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-lg-8 p-md-12">
                    <div class="p-grid p-formgrid">
                        <div class="p-field p-col-12 p-lg-5 p-md-12 p-sm-12">
                            <span>
                                <label style="margin-top: 10px;">Period:</label>
                                <p-dropdown [options]="months" optionLabel="Name" [(ngModel)]="month" autoWidth="false"
                                    (onChange)="SelectPeriod($event)"></p-dropdown>
                            </span>
                        </div>

                        <div class="p-field p-col-12 p-lg-5 p-md-12 p-sm-12">
                            <span>
                                <label style="margin-top: 10px;">Requested By:</label>
                                <p-multiSelect [options]="requestedby" autoWidth="false" [(ngModel)]="selectedUser"
                                    (onChange)="selectedUsersChange($event)" optionLabel="fullName"
                                    class="multiselect-custom customMulti" defaultLabel="Select" [filter]="true"
                                    itemSize="30">
                                    <!-- <ng-template let-value pTemplate="selectedItems">
                                        <div class="country-item country-item-value"
                                            *ngFor="let option of selectedMulti">
                                            <div>option.name</div>
                                        </div>
                                        <div *ngIf="!selectedMulti || selectedMulti.length === 0"
                                            class="country-placeholder">
                                            Select
                                        </div>
                                    </ng-template> -->
                                </p-multiSelect>
                            </span>
                        </div>
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <a>
                                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                                    (click)="GetReportData()" class="p-button-raised p-button-primary"
                                    style="width:100%; margin-top: 18px;"></button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <hr>
            <div class="p-grid">
                <div class="p-col-12 p-lg-6 p-md-12 p-sm-12">
                    <div class="card" style="height:400px;overflow-x: auto;">
                        <div class="card-header" style="padding-bottom:0px;">
                            <h4>Change Request by Status</h4>
                        </div>
                        <hr>
                        <div class="p-grid p-formgrid">
                                <div *ngIf="isChartDataAvailable;else Chartemptymessage;"
                                class="revenue-chart-container" style="width: 450px;">
                                    <p-chart type="pie" id="revenue-chart" [data]="StatusChartData" [options]="options">
                                    </p-chart>
                                </div>                           
                            <ng-template #Chartemptymessage>
                                <div style="text-align: center;margin-left: 15px;">No status found.</div>
                            </ng-template>                       
                    </div>
                </div>
                </div>
                <div class="p-col-12 p-lg-6 p-md-12 p-sm-12">
                    <div class="card" style="height:400px;overflow-x:auto;">
                        <div class="card-header" style="padding-bottom:0px;">
                            <h4>Change Request by Change Type</h4>
                        </div>
                        <hr>
                        <div class="p-grid p-formgrid">                           
                                <div *ngIf="isChangeTypeDataAvailable;else ChangeTyeemptymessage;"
                                    class="revenue-chart-container" style="width: 450px;">
                                    <p-chart type="pie" id="revenue-chart" [data]="ChangeTypeData"
                                        [options]="ChangeTypeoptions">
                                    </p-chart>
                                </div>                            
                            <ng-template #ChangeTyeemptymessage>
                                <div style="text-align: center;margin-left: 15px;">No status found.</div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="card-header"
                    style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px;width: 70%;">Change Requests</h5>
                    <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                        <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
                            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                            style="float:right;height:25px; width:25px;"></button>
                        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()"
                            pTooltip="Download" tooltipPosition="top"
                            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                            style="float:right; height:25px; width:25px;"></button>

                    </span>
                </div>
                <div class="invoice invoice-header" id="invoice-content">
                    <p-table (sortFunction)="customSort($event)" [customSort]="true"  #dt [value]="changeRequestData" id="excel-table" [columns]="cols"  [scrollable]="true" scrollHeight="250px"
                        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                        dataKey="id"
                        [globalFilterFields]="['ecrn','changeGroupName','changeItem','statusName','additionalInfo','owner','createdAt']">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'cItem':i==2,'status': i == 3,'ecrn':i==0,'reqBy':i==5,'reqDate':i==6,'cType':i==1,
                                'additionalInfo':i==4}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th style="width:80px;">Actions</th>
                            </tr>
                            <tr id="trSearch">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                        (input)="dt.filter($event.target.value, col.field, 'contains')"
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                    <td *ngIf="i == 0" style="text-align: left;padding-left:15px; width: 123px;">
                                        {{ rowData[col.field] }}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: left;padding-left:10px; width: 140px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px; width: 270px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px; width: 120px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: center;width: 250px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: left; width: 140px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center; width: 140px;">
                                        {{rowData[col.field]}}
                                    </td>
                                </ng-container>
                                <td style="text-align: center;width:80px;">
                                    <button pButton type="button" (click)="changeRequest(rowData)" icon="pi pi-eye"
                                        pTooltip="View" tooltipPosition="right" class="p-button-raised p-button-primary"
                                        style="width:25px; height:25px;text-align:center;"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7" style="text-align: left;">No records found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

            </div>
        </div>
    </div>

    <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
        *ngIf="showLoading">
        <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
    </div>