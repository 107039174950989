<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="p-fluid p-formgrid p-grid">
        <div class=" p-col-12 p-md-2">
          <a (click)="BackClick()"> <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left"
              label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width:auto;"></button></a>
        </div>
        <div class=" p-col-12 p-md-8" style="text-align:center;">
          <h4 style="margin-top:5px;">Product Change Request</h4>
        </div>

        <div class=" p-col-12 p-md-2">
          <span
            style="font-weight: bold;color:#1976d2;font-size: 16px;float: right;margin-top: 10px;margin-right: 10px;">
            <a (click)="ViewCCB()" style="cursor: pointer">View CCB</a></span>
        </div>
      </div>

      <hr /><br>
      <div class="card">
        <h4 style="margin-top:5px;">Change Request Details</h4>
        <hr>
        <div class="p-fluid p-formgrid p-grid">

          <div class="p-field p-col-12 p-md-4">
            <label for="Request Number">ECRN</label>
            <input id="Title" type="text" pInputText placeholder="Request Id #" [disabled]="true" [(ngModel)]="ECRN"
              style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="Request Number">Status</label>
            <input id="ProdStatus" type="text" pInputText placeholder="Status" [(ngModel)]="ProdStatus"
              [disabled]="true" style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="Request Number">Change Type</label>
            <input id="ChangeType" type="text" pInputText placeholder="Change Type" [(ngModel)]="ChangeType"
              [disabled]="true" style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="city">Date<span style="color: red">*</span></label>
            <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate" [(ngModel)]="selectedDate"
              [disabled]="isReadOnly"></p-calendar>
            <small class="p-invalid">{{DateError}}</small>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="Description">Description<span style="color: red">*</span></label>
            <textarea id="Description" type="text" rows="2" [maxLength]="300" pInputTextarea [(ngModel)]="Description"
              [disabled]="isReadOnly" style="opacity: 0.9;"></textarea>
            <small class="p-invalid">{{DescrptionError}}</small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="BusinesBenifits">Reasons<span style="color: red">*</span></label>
            <textarea id="BusinesBenifits" type="text" rows="2" [maxLength]="300" pInputTextarea
              [(ngModel)]="BusinesBenifits" [disabled]="isReadOnly" style="opacity: 0.9;"></textarea>
            <small class="p-invalid">{{ReasonsError}}</small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="Justification">Justification/Business Benifits</label>
            <textarea id="Justification" type="text" rows="2" [maxLength]="300" pInputTextarea
              [(ngModel)]="Justification" [disabled]="isReadOnly" style="opacity: 0.9;"></textarea>
          </div>
          <!-- <div class=" p-field p-col-12 p-md-4 p-formgroup-inline" >
                    <label for="Description">Required Files</label>
                    <div class="p-field" style="width: 100%;">
                      <p-fileUpload  #fileInput name="file" multiple="multiple" accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png"
                      maxFileSize="1000000" [showUploadButton] = "false" [showCancelButton]="false" fileLimit="5" auto="true" >
                        <ng-template let-file pTemplate='file'>
                          <span>
                            {{file.name | slice:0:40}} <button class="p-button-danger" style="width: 25px; height: 25px;"  icon="pi pi-trash" iconPos="left" pButton type="button" label="" (click)="removeFile(file, fileInput)"></button>
                          </span>

                      </ng-template>
                      </p-fileUpload>

                    </div>

                </div> -->
          <div class="p-field p-col-12 p-md-4" *ngIf="ProdStatus!='New' && commentsList.length > 0">
            <label>Comments</label>&nbsp;&nbsp;

            <button pButton pRipple type="button" icon="pi pi-eye" pTooltip="View" tooltipPosition="top"
              class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="height: 28px; width: 28px"
              (click)="commentsDisplay = true"></button>
          </div>
        </div>
      </div>
      <div class="card">
        <h4 style="margin-top:5px;">Product Details</h4>

        <hr>
        <div class="p-fluid p-formgrid p-grid">

          <div class="p-field p-col-12 p-md-4">
            <label for="Part Number">Part Number</label>
            <input id="PartNo" type="text" pInputText placeholder="Part #" [(ngModel)]="PartNo" [disabled]="true"
              style="opacity: 0.9;" />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="Name">Project Name</label>
            <input id="PartName" type="text" pInputText placeholder="Project Name" [(ngModel)]="PartName" [disabled]="true"
              style="opacity: 0.9;" />
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="Request Number">Product Engineer</label>
            <input id="ProdOwner" type="text" pInputText placeholder="Product Engineer" [(ngModel)]="ProdOwner"
              [disabled]="true" style="opacity: 0.9;" />
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="Request Number">Current Phase</label>
            <input id="ActivePhase" type="text" pInputText placeholder="Phase" [(ngModel)]="ActivePhase"
              [disabled]="true" style="opacity: 0.9;" />
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="Description">Description</label>
            <textarea id="Description" type="text" rows="2" [maxLength]="300" pInputTextarea
              [(ngModel)]="ItemDescription" [disabled]="true" style="opacity: 0.9;"></textarea>
          </div>

        </div>

      </div>
      <div class="card" *ngIf="ProdStatus=='New'">
        <h4 style="margin-top:5px;">Product Attributes</h4>
        <span style="font-weight: bold; color:red;">Unless there is an entry error correction to be made, any other
          request for change in the FG part#, MFG Part#, Die Code, Die Code Rev, Package Name should be
          redirected/instructed as a new Project ID creation.</span>
        <hr>
        <div class="p-fluid p-formgrid p-grid">
          <!-- <div class="p-field p-col-12 p-md-2"></div> -->
          <div class="p-field p-col-12" style="min-height:200px;overflow-x: auto;">
            <p-table #dt [columns]="cols" [value]="CustomFieldgroupId" [(selection)]="selectedCustomField"
              dataKey="customFieldID" styleClass="p-datatable-striped" class="table-align" [rowHover]="true"
              [paginator]="false" [filterDelay]="0">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th style="width: 300px;">
                    Attribute
                  </th>
                  <th style="width: 300px;">
                    Current
                  </th>
                  <th style="width: 300px;">
                    Proposed
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                    <td *ngIf="i == 0 && rowData.view"
                      style="text-align: left;padding-left:25px; ">
                      {{ rowData.fieldName }} </td>

                    <td *ngIf="i == 1 && rowData.view" style="text-align: left">
                      {{ rowData.valueName }}
                    </td>
                    <td *ngIf="i == 2 && rowData.view">
                      <select class="select-css" *ngIf="rowData.entryType === 'DropDown'"
                        [(ngModel)]="datasourcelist[rowData.customFieldID.toString()].value" [disabled]="isReadOnly"
                        optionLabel="--select--">
                        <option *ngFor="let item of datasourcelist[rowData.customFieldID.toString()].list"
                          [value]="item.id">
                          {{item.name}}
                        </option>
                      </select>
                      <input *ngIf="rowData.entryType === 'TextBox'"
                        [(ngModel)]="datasourcelist[rowData.customFieldID.toString()].value" type="text" pInputText
                        (change)="keyPressNumbers($event,rowData.fieldDataType)" [attr.maxlength]="rowData.length"
                        [readonly]="isReadOnly" />
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="3" style="text-align: left;">No custom fields found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="ProdStatus!='New'">
        <h4 style="margin-top:5px;">Product Attributes</h4>
        <span style="font-weight: bold; color:red;">Unless there is an entry error correction to be made, any other
          request for change in the FG part#, MFG Part#, Die Code, Die Code Rev, Package Name should be
          redirected/instructed as a new Project ID creation.</span>
        <hr>
        <div class="p-fluid p-formgrid p-grid">
          <!-- <div class="p-field p-col-12 p-md-2"></div> -->
          <div class="p-field p-col-12" style="min-height:200px;overflow-x: auto;">
            <p-table #dt [columns]="cols" [value]="CustomFieldgroupId" [(selection)]="selectedCustomField"
              dataKey="customFieldID" styleClass="p-datatable-striped" class="table-align" [rowHover]="true"
              [paginator]="false" [filterDelay]="0">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th style="width: 300px;">
                    Attribute
                  </th>
                  <th style="width: 300px;">
                    Current
                  </th>
                  <th style="width: 300px;">
                    Proposed
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData"
                  [ngStyle]="{'background-color': ((rowData.valueName != rowData.proposed && rowData.entryType=='TextBox')||(rowData.valueName != datasourcelist[rowData.customFieldID.toString()].value)) && isReadOnly? '#ffe5b4' : ''}">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                    <td *ngIf="i == 0"
                      style="text-align: left;padding-left:25px; ">
                      {{ rowData.fieldName }} </td>

                    <td *ngIf="i == 1" style="text-align: left">
                      {{ rowData.valueName }}
                    </td>
                    <td *ngIf="i == 2">

                      {{datasourcelist[rowData.customFieldID.toString()].value}}
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="3" style="text-align: left;">No custom fields found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="ProdStatus!='New'">
        <h4 style="margin-top:5px;">Approval History</h4>
        <hr>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12" style="min-height:200px;overflow-x: auto;">
            <p-table #dt [value]="approvalsHistory" styleClass="p-datatable-striped" class="table-align"
              [rowHover]="true" [paginator]="false" [filterDelay]="0">
              <ng-template pTemplate="header" class="invoice-items">
                <tr>
                  <th style="width: 200px">
                    Approved By
                  </th>
                  <th style="width: 150px">
                    Approved Date
                  </th>
                  <th style="width: 200px">
                    Department
                  </th>
                  <th style="width:200px">
                    Role
                  </th>
                  <th style="width: 100px">
                    Status
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-approvalsHistory>
                <tr [pSelectableRow]="approvalsHistory">
                  <ng-container>

                    <td style="text-align: left;padding-left:25px;">
                      {{ approvalsHistory.approvedBy }} </td>

                    <td style="text-align: center;">
                      {{ approvalsHistory.approvedDate}}
                    </td>
                    <td style="text-align: left; padding-left:25px;">
                      {{ approvalsHistory.department }}
                    </td>
                    <td style="text-align: left;padding-left:25px;">
                      {{ approvalsHistory.role }}
                    </td>
                    <td style="text-align: center;">
                      {{ approvalsHistory.status }}
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" style="text-align: left;">No data found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-field p-col-12 p-lg-7 p-md-12 p-sm-12"></div>
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
         <button *ngIf="isFromOther" pButton pRipple type="button" (click)="ChangeRequest('')" icon="pi pi-check"
            iconPos="left" label="Save" class="p-button-raised p-button-success" style="width: 100%;"></button> 
            <button *ngIf="isFromApprovalNeeded" pButton pRipple type="button" (click)="approveRej(3)"
                icon="pi pi-check" iconPos="left" label="Approve" class="p-button-raised p-button-success"
                style="width: 100%;"></button>
        </div>
        <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">            
            <span class="p-input-icon-left" style="float: right;">
              <button *ngIf="isFromOther&&!isInprocess" pButton pRipple type="button" (click)="ChangeRequest('SP')"
                icon="pi pi-check" iconPos="left" label="Save and Send for Approval"
                class="p-button-raised p-button-success" style="width: 100%;"></button> 
              <button *ngIf="isFromApprovalNeeded" pButton pRipple type="button" (click)="approveRej(4)"
                icon="pi pi-times" iconPos="left" label="Reject" class="p-button-raised p-button-danger"
                style="width:100%;"></button>              
            </span>
          <small class="p-invalid" style="float: right">{{txtErrorMessage}}</small>
        </div>

      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>

<p-dialog header="Reject Change Request" [(visible)]="rejectdisplay" modal="modal" showEffect="fade"
  [style]="{ width: '60%' }">
  <hr />
  <div class="p-grid p-fluid">
    <div class="p-col-12" style="text-align: left">
      <label for="Rejectcomments">Are you sure you want to reject the change request ? please specify
        the reason.</label>
      <textarea [(ngModel)]="Rejectcomments" id="Rejectcomments" type="text" rows="4" [maxLength]="300"
        pInputTextarea></textarea>
    </div>
    <div class="p-col-12 p-md-8"></div>
    <div class="p-col-12 p-md-2"></div>
    <div class="p-col-12 p-md-2">
      <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
        <button pButton type="button" label="Continue" icon="pi pi-times" iconPos="left"
          (click)="ApproveRejectRequest(4)" class="p-button-raised p-button-danger"
          style="margin-top: -5px; margin-bottom: -5px"></button>
      </span>
    </div>
    <small class="p-invalid" style="float: right">{{txtErrorMessageAR}}</small>
  </div>
</p-dialog>

<p-dialog header="Approve Change Request" [(visible)]="approvedisplay" modal="modal" showEffect="fade"
  [style]="{ width: '60%' }">
  <hr />
  <div class="p-grid p-fluid">
    <div class="p-col-12" style="text-align: left">
      <label for="Comments">Comments:</label>
      <textarea [(ngModel)]="Comments" id="Comments" type="text" rows="4" [maxLength]="300" pInputTextarea></textarea>
    </div>

    <div class="p-col-12 p-md-8"></div>
    <div class="p-col-12 p-md-2"></div>
    <div class="p-col-12 p-md-2">
      <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
        <button pButton type="button" label="Continue" icon="pi pi-check" iconPos="left"
          (click)="ApproveRejectRequest(3)" class="p-button-raised p-button-success"
          style="margin-top: -5px; margin-bottom: -5px"></button>
      </span>
    </div>
    <small class="p-invalid" style="float: right">{{txtErrorMessageAR}}</small>
  </div>
</p-dialog>
<p-dialog header="Comments" [(visible)]="commentsDisplay" modal="modal" showEffect="fade" [style]="{ width: '60%' }">
  <hr />
  <div class="p-grid">
    <div class="p-col-12" style="text-align: center">
      <div *ngIf="commentsList.length > 0" class="card no-gutter widget-overview-box widget-overview-box-1"
        style="min-height: 230px; overflow-y: scroll">
        <ul class="widget-image-list" *ngFor="let appr of commentsList">
          <li>
            <span class="listitem-value">
              <ul>
                <li style="text-align: left">
                  <div style="float: left">
                    <span style="
                              color: red;
                              font-size: 16px;
                              padding-right: 10px;
                            ">{{ appr.approvedBy }}:</span>
                    <span style="
                              color: #2196f3;
                              font-size: 14px;
                              padding-right: 10px;
                            ">{{
                      appr.approvedDate
                      | date: "MM/dd/yyyy hh:mm a"
                      }}:</span>{{ appr.comment }}
                  </div>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
      <div *ngIf="commentsList.length <= 0" class="card no-gutter widget-overview-box widget-overview-box-1"
        style="min-height: 230px; overflow-y: scroll">
        <ul class="widget-image-list">
          <li>
            <span class="listitem-value">
              <ul>
                <li style="text-align: left">
                  <div style="float: left">
                    <span style="font-size: 16px; padding-right: 10px">No Comments to show.</span>
                  </div>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="CCB Board" [(visible)]="ShowCCB" modal="modal" showEffect="fade" [style]="{ width: '80%' }">
  <hr />
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12" style="min-height:200px;overflow-x: scroll;">
      <span style=" font-weight: bold;color: #2196f3;">Product Line: {{productLineName}}</span>
      <p-table #dt [value]="ccbList" styleClass="p-datatable-striped" class="table-align" [rowHover]="true"
        [paginator]="false" [filterDelay]="0">
        <ng-template pTemplate="header" class="invoice-items">
          <br>
          <tr>
            <th style="width:auto;">
              User Name
            </th>
            <th style="width: auto;">
              Department
            </th>
            <th style="width: auto;">
              Role
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ccb>
          <tr [pSelectableRow]="ccb">
            <ng-container>
              <td style="text-align: left; padding: 1rem;">
                {{ ccb.userName }}
              </td>
              <td style="text-align: left;padding: 1rem;">
                {{ ccb.departmentName }} </td>

              <td style="text-align: left;padding: 1rem;">
                {{ ccb.roleName}}
              </td>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" style="text-align: left;">No data found...</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>