<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-12 p-lg-12 p-md-12 p-sm-12">
                    <div class="card-header" id="cardheader"
                        style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">Change Management System Help Guide</h5> 
                       <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                            <button *ngIf="showplus" pButton pRipple type="button" icon="pi pi-plus" pTooltip="Expand" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>

                                <button *ngIf="showminus" pButton pRipple type="button" icon="pi pi-minus" pTooltip="Collapse" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>                           
                        </span>
                    </div><br><br>
                    <p-accordion>
                        <p-accordionTab header="Create Change Request" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#NewDocument">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">New Document</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ReviseDocument">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Revise Document</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ECO">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">ECO</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ResubmitDocument">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-4">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Resubmit Document</span>
                                        </div>
                                    </a>
                                </div>
                                </div>
                                <div class="p-grid p-fluid p-formgrid" style="margin-top:20px;">                                
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Product">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Product</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Project">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Project</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="My Change Requests" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MyChangeRequests">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Change Requests</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="My Approvals" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MyApprovals">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">My Approvals</span>
                                    </div>
                                    </a>
                                </div>                              
                            </div>                            
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Reports" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Reports">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Reports</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Admin" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Admin">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Change Control Board</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>

        <div class="card" style="min-height:600px;">
            <div class="p-grid p-formgrid p-fluid">
                <!-- <h3 style="margin-left: 20px;">My Documents</h3><br> -->
                <div id="NewDocument">
                    <p style="font-size: large;margin-left: 20px;"><strong>Create Change Request:</strong>  It is the first menu item. If a user wants to create any change request, then he/she can select from the available submenus like Change request for New Document or Revise Document ECO or Resubmit Document or Product or Project. </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Document Creation Process in Change Management:</strong> </p>
                    <img src="assets/gallery/createchangerequest.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br>
                    <p style="font-size: large;margin-left: 20px;"><strong>Create Change Request > New Document:  </strong> Here user can create a change request for a new document.</p>
                    <img src="assets/gallery/newdocument.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br>
                    <p style="font-size: large;margin-left: 20px;">After filling in all the required details in the above form, when the user clicks on the “Save” button, ECRN will be generated with the status as “New”. </p>
                    <img src="assets/gallery/newdocument1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br>  
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on the “Continue” to create a document then it leads to creating a new document page. </p>
                    <img src="assets/gallery/newdocument2.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br>  
                    <p style="font-size: large;margin-left: 20px;">After filling the above form, the user has to click on the “save” button; once the user clicks on the save button, a document id will be generated, which means the document was created and the user can find it as a New Document part of Document Management System.</p>
                    <p style="font-size: large;margin-left: 20px;">(Instead of clicking on Save, if the user clicks on Save and Send for Approval, then the Document will be created and will be sent for approval and the status becomes “In-Process”)</p>
                    <img src="assets/gallery/newdocument3.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">By clicking on the view button, the user can see document details.</p>
                    <img src="assets/gallery/newdocument4.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">When the user clicks on the send for approval, this document will be sent for approval and the status becomes “In-Process”.</p>
                    <img src="assets/gallery/newdocument5.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br><br>
                </div>
                <div id="ReviseDocument">
                    <p style="font-size: large;margin-left: 20px;"><strong>Create Change Request > Revise Document:  </strong> On this page, the user can see all documents which are created from Change Management as a list with Revision Number.</p>  
                    <img src="assets/gallery/revisedocument.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on the “plus” icon, then the user can create a change request to  revise the document</p>
                    <img src="assets/gallery/revisedocument1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;" >
                    <br>    
                    <p style="font-size: large;margin-left: 20px;">After clicking on the save button, the Revision number will change from 1 to 2, then the status will be “New”. If the user clicks on the view icon, then the document details page will open, then the user has to click on the “Actions” button and click on Revise Document, then the user has to attach the revised document and click on save and send for approval. After the approval process, the document will be considered as “revised”.</p>
                    <p style="font-size: large;margin-left: 20px;">In the above image, instead of clicking on the “Save” button, if the user clicks on the Continue to revise the document, then the user can directly go to the document details page. There, the user has to attach the revised document and click on save and send for approval. After the approval process, the document will be considered as “revised”.</p>
                    <br><br>
                </div>
                <div id="ECO">
                    <p style="font-size: large;margin-left: 20px;"><strong>Create Change Request > ECO: </strong> On this page, the user can see all documents which are created from Change Management as a list.</p>
                    <img src="assets/gallery/eco.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">By clicking on the “plus” icon, the user can create a change request for the ECO document.</p>
                    <img src="assets/gallery/eco1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">After clicking on the “save”, ECRN document will be generated, then the status will be shown as “new”.</p> 
                    <img src="assets/gallery/eco2.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on the view icon, then the same change request details page will open up.</p>
                    <img src="assets/gallery/eco3.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">From here, the user can click on the “Continue” to Create the ECO so that Engineering Change Order page will open; there the user has to provide the date in the required fields and click on “choose file” to attach the document.</p>
                    <img src="assets/gallery/eco4.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">After filling the required fields, the user has to click on the “choose file” button and the user has to attach a document. Then, click on the “Save and send for approval” button.</p>
                    <p style="font-size: large;margin-left: 20px;">With the above, ECO will be created and sent for approval; after all the approvals the document will have ECO.</p>
                    <br><br>
                </div>     
                <div id="ResubmitDocument">
                    <p style="font-size: large;margin-left: 20px;"><strong>Create Change Request > Resubmit:</strong> When the user clicks on the Resubmit Document menu item, then the withdrawn or rejected documents list will</p> 
                    <img src="assets/gallery/resubmit.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Appear. By clicking on the “+” icon, the user can see  the “create change request” form and can enter the required details.</p>
                    <img src="assets/gallery/resubmit1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;"> If the user clicks on continue to Resubmit the Document, then the “Resubmit Document” page will open; then the user has to click on “Choose file” to attach the document; then click on “Send for Approval”.</p>
                    <img src="assets/gallery/resubmit2.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">Then the document will be re-submitted and sent for approval and the status becomes “in-process”</p>
                    <img src="assets/gallery/resubmit3.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">When all the approvers approve, then the document will become an “Approved” Document. </p>
                    <br><br>
                </div>
                <div id="Product">
                    <p style="font-size: large;margin-left: 20px;"><strong>Create Change Request > Product:</strong> Click on the Product submenu to see products list</p>
                    <img src="assets/gallery/product.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">By clicking on the “plus” icon, user can see the Product Change Request page, there the user can find Change Request Details section where the user has  to fill all the required fields. In the Product Details section, user can find Product Attributes section.</p>
                    <p style="font-size: large;margin-left: 20px;">In the Product Attributes section, for FG Part Number, Die Code (Root), Die Code Rev (d), Die Code Rev (e), the user has to provide the desired Die Code number. Then click on the “Save” button or click on the “Save and Send for Approval”.</p>
                    <img src="assets/gallery/product1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>                                           
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on the “Save” button, then the Product will be saved with the added Die code number with the status “New”</p>
                    <img src="assets/gallery/product2.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">When the user clicks on the View button, then the same Product Change Request page will open up, then the user can click on the Save and Send for Approval button. In this case,  the Product will be saved with the added Die code number with the status as “in-process”.</p>
                    <img src="assets/gallery/product3.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">The updated Die code has to be approved by all listed approvers so that the Product will have a new “Die Code”.</p>
                    <br><br>
                </div>    
                <div id="Project">
                    <p style="font-size: large;margin-left: 20px;"><strong>Create Change Request > Project:</strong> If the user clicks on the “Project” submenu, the user can see projects list</p>
                    <img src="assets/gallery/project.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">By clicking on the “plus” icon, the user can see the “Project Change Request” Page.</p>
                    <img src="assets/gallery/project1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>    
                    <p style="font-size: large;margin-left: 20px;">Here on this page, the user has to fill in all the required fields. Below the Project Details, the user can find the Dates section in which the user has an option to provide the required dates.</p>
                    <p style="font-size: large;margin-left: 20px;">User can see Current and Proposed Project Start Date,  Current and Proposed Project End Date. Here, the user has to  provide the Proposed Start Date and Proposed Target Date.</p>
                    <img src="assets/gallery/project2.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>    
                    <p style="font-size: large;margin-left: 20px;">After providing the dates, the user can click on the “save” button, then the project will be saved with status as “new”. If the user clicks on the view button, then the user sees the same Project Change Request Page. There the user has to click on save and send for approval, then the Project will be saved with the status “in-process”.</p>
                    <img src="assets/gallery/project3.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>    
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on the view button, then the user sees the same Project Change Request Page. There the user has to click on save and send for approval, then the Project will be saved with the status “in-process”.</p>
                    <img src="assets/gallery/project4.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">Once it is approved by all its approvers, the Project Start Date will be changed. If the user likes to see the approvers list, click on the View CCB button, which is placed at the top right corner of the Project Change Request page.</p>
                    <img src="assets/gallery/project5.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>     
                    <p style="font-size: large;margin-left: 20px;">By clicking on the View CCB button, the user can see a popup with Approvers list.</p>
                    <img src="assets/gallery/project6.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br><br>
                </div>  
                <div id="MyChangeRequests">
                    <p style="font-size: large;margin-left: 20px;"><strong>My Change Requests:</strong> On this page, the user can see all the change requests that have been submitted.</p>
                    <p style="font-size: large;margin-left: 20px;">All the list items have different Statuses according to the action, New, In-Process and Approved.</p>
                    <p style="font-size: large;margin-left: 20px;">If it is New, the user can send it for approval, if it is In-Process then the user can send a reminder. If it is approved, then the user can use it as is. </p>
                    <img src="assets/gallery/mychangerequests.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, the user can also observe there is a date filter and status of the item provided so that the user can select the particular period to review the change requests.</p>
                    <img src="assets/gallery/mychangerequests1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>    
                    <p style="font-size: large;margin-left: 20px;">According to the selection, user can find all change requests in the grid., If the user clicks on the view button, then the change request item details can be seen.</p>
                    <br><br>
                </div>  
                <div id="MyApprovals">
                    <p style="font-size: large;margin-left: 20px;"><strong>My Approvals:</strong> Every change request has to be approved by its approvers from appropriate departments, here in the “My Approvals” page which is user-specific and change requests could be listed as a grid.</p>
                    <img src="assets/gallery/myapprovals.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br> 
                    <p style="font-size: large;margin-left: 20px;"> This approval list can also be filtered according to the date filter and by the status.</p>
                    <img src="assets/gallery/myapprovals1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>    
                    <p style="font-size: large;margin-left: 20px;"> Here user can see the list of change requests waiting for approval, approved and also rejected.</p>
                    <br><br>
                </div>  
                <div id="Reports">
                    <p style="font-size: large;margin-left: 20px;"><strong>Reports:</strong> Two types of Reports can be seen here: Change request by status and Change request by change type. </p>
                    <img src="assets/gallery/reports.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;"> User can also see the Dropdown selection.</p>
                    <img src="assets/gallery/reports1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>    
                    <p style="font-size: large;margin-left: 20px;">As per the selection of the dropdown list, the user can see the result of the selection in the form of a pie chart, also in the table grid accordingly.</p>
                    <img src="assets/gallery/reports2.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br><br>
                </div>  
                <div id="Admin">
                    <p style="font-size: large;margin-left: 20px;"><strong>Admin:</strong> This role is specific and permission is limited to specific users.</p>
                    <p style="font-size: large;margin-left: 20px;">By clicking on the “admin”, user can see the Change Control Board page. User can see previously created CCB Boards for Product lines, and can also see the “Create New CCB Board” Button.</p>
                    <img src="assets/gallery/admin.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">By clicking on the Create New CCB Board button, the user can see the Create CCB page.</p>
                    <img src="assets/gallery/admin1.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Here the user has to provide the required fields and has  to click on the “Add” button, then those added details will be visible as a list.</p>
                    <img src="assets/gallery/admin2.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>                          
                    <p style="font-size: large;margin-left: 20px;">After Creating the CCB, the user can also edit the details.</p>
                    <img src="assets/gallery/admin3.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">By clicking on the Edit Button, the user can update the details.</p>
                    <img src="assets/gallery/admin4.jpg" style="width: 55%;height:auto;display: block;margin-left: 15%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">Once board members are added, click on the Update button.</p>
                     <br><br>
                </div>  
                    
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-10"></div>
            <div class="p-field p-col-12 p-md-2">
            <a href="#cardheader" >
                <button pButton pRipple type="button" icon="pi pi-arrow-up" pTooltip="Go to Top"
                tooltipPosition="top" class="p-button-rounded p-button-info p-mr-2 p-mb-2"
                style="float:right;height:25px; width:25px; "></button>
                </a>        
        </div>  
    </div>
    </div>
</div>

