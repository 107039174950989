<div class="layout-sidebar" (click)="onMenuClick($event)">
  <a class="logo">
    <img
      id="app-logo"
      class="logo-image"
      src="assets/gallery/pficon.png"
      alt="FactoryPRO-Logo"
      style="width: 60px; height: 60px; margin-left: -0.5rem"
      (click)="LogoClick()"
    />
  </a>

  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of model; let i = index">
        <li
          app-menuitem
          *ngIf="!item.separator" 
          [item]="item"
          [index]="i"
          [root]="true"
        ></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
      </ng-container>
    </ul>
  </div>
</div>
<p-dialog header="Access" [(visible)]="InfoDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}" >
  <hr>
  <div class="p-grid">
 
  <div class="p-col-12" style="text-align: center;">
   <span style="font-size:18px;">User doesn't have permission to perform the operation</span>
  </div>
  </div>
  </p-dialog>